<template>
  <div :id="containerName" style="width: 100%; height: 100%"></div>
</template>

<script>
import basemixin from "../mixins";
import * as d3 from "d3";
import _ from "lodash";

export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    init() {
      const _this = this;
      this.clear();
      this.getSize();
      const svg = this.creatSVG();
      let { newick } = this.okData;
      const cluster = this.creatCluster(newick);
      const { nodes, root_type_root } = this.creatNodes(cluster, newick);
      const roots = cluster.links(nodes);
      const l = this.getMaxLabelLength(nodes);
      this.maxLableWidth = l * this.textWidth;
      const maxLength = this.getMaxLength(nodes);
      const x = this.creatX(maxLength);

      this.visitPreOrder(root_type_root, function (node) {
        node.real_y = x(node.rootDist);
      });

      this.drawLinkExtension(svg, roots);
      this.drawLink(svg, roots);
      this.drawPoint(svg, roots);
      this.drawText(svg, nodes);

      this.drawGroup(svg, nodes);
      this.drawTable(svg, nodes);

      if (this.isJL) {
        const X = this.drawX(svg, maxLength);
        this.drawGridLabel(svg, nodes);
        this.drawGridValue();
        this.drawMaxLine({ svg, X, max: maxLength });
        this.drawLength({ svg, root: nodes, X, max: maxLength });
      }

      this.bindZoom("lx-svg__" + this.containerName);
    },
    drawGridValue() {
      const _this = this;
      if (!_this.options.showGrid_Value) {
        return;
      }
      const h = this.nodeHeight;
      d3.selectAll(".axis--x .tick")
        .append("line")
        .attr("class", "bg-line")
        .attr("stroke-width", 2)
        .attr("stroke", "rgb(0,0,0)")
        .attr("stroke-dasharray", "10,10")
        .attr("stroke-opacity", 0.1)
        .attr("shape-rendering", "crispEdges")
        .attr("y2", h + 10);
    },
    drawGridLabel(svg, root) {
      const _this = this;
      if (!_this.options.showGrid_Label) {
        return;
      }
      const d = root.filter((d) => d.type == "leaf");
      const labelLine = svg
        .append("g")
        .selectAll("line")
        .data(d)
        .enter()
        .append("line");

      labelLine
        .attr("class", "bg-line")
        .attr("stroke-width", 2)
        .attr("stroke", "rgb(0,0,0)")
        .attr("stroke-opacity", 0.1)
        .attr("stroke-dasharray", "10,10")
        .attr("shape-rendering", "crispEdges")
        .attr("y1", (d) => d.x)
        .attr("y2", (d) => d.x)
        .attr("x2", _this.options.width)
        .attr("x1", -20);
    },
    drawX(svg, maxDist) {
      const _this = this;
      const x = d3.scale
        .linear()
        .domain([maxDist, 0])
        .range([0, this.options.width]);

      const axisX = d3.svg
        .axis()
        .scale(x)
        .orient("top")
        .ticks(maxDist < 10 ? maxDist : 10);
      svg.append("g").call(axisX).attr("class", "axis axis--x");
      d3.select(".axis--x")
        .select("path")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("fill", "none");
      svg
        .append("text")
        .attr("y", -35)
        .attr("x", _this.options.valueTitleLeft)
        .style("text-anchor", "middle")
        .attr("fill", "#000")
        .text(_this.options.valueTitle);
      return x;
    },
    creatX(maxDist) {
      const _this = this;
      const x = d3.scale
        .linear()
        .domain([0, maxDist])
        .range([0, this.options.width]);

      return x;
    },
    getMaxLength(nodes) {
      var rootDists = nodes.map(function (n) {
        return n.rootDist;
      });
      const maxDist = d3.max(rootDists);
      return maxDist;
    },
    creatSVG() {
      const _this = this;
      let main = d3.select("#" + this.containerName);
      const viewBox = [-100, -100, this.width + 100, this.height + 100];
      const svg = main
        .append("svg")
        .attr("id", "lx-svg__" + this.containerName)
        .attr("viewBox", viewBox)
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("width", "100%")
        .attr("height", this.height);
      return svg;
    },
    getMaxLabelLength(root) {
      const d = root.filter((d) => d.type == "leaf");
      const maxLengthLabel = _.maxBy(d, (t) => t.name.length);
      const length = maxLengthLabel.name.length;
      return length;
    },
    creatCluster(root) {
      const h = this.nodeHeight;
      let cluster1 = d3.layout
        .cluster()
        .size([h, this.options.width])
        .separation(function (a, b) {
          return 1;
        });
      return cluster1;
    },
    visitPreOrder(node, callback) {
      callback(node);
      if (node.children) {
        for (var i = node.children.length - 1; i >= 0; i--) {
          this.visitPreOrder(node.children[i], callback);
        }
      }
    },
    creatNodes(cluster, root) {
      const max = this.options.maxValue;
      let nodes = cluster(root)
        .map((n, i) => {
          let type = "leaf";
          if (n.children) {
            if (n.depth === 0) {
              type = "root";
            } else {
              type = "inner";
            }
          }
          n.type = type;
          n.id = i;
          return n;
        })
        .sort(function (a, b) {
          if (_.isEmpty(a.children)) {
            return 1;
          }
          if (_.isEmpty(b.children)) {
            return -1;
          }
          if (a.children.length > b.children.length) {
            return -1;
          }
          return 1;
        });
      const root1 = nodes.find((n) => {
        return n.type === "root";
      });
      this.visitPreOrder(root1, function (node) {
        let branchLength = 0;
        if (node.data && node.data.length) {
          branchLength = node.data.length > 0 ? node.data.length : 0;
        }
        node.rootDist = 0;
        if (node.parent) {
          if (node.parent.rootDist) {
            node.rootDist = node.parent.rootDist;
          }
        }
        node.rootDist += branchLength;
        if (node.rootDist > max) {
          node.rootDist = max;
        }
      });
      return { nodes, root_type_root: root1 };
    },
    drawMaxLine({ svg, X, max }) {
      const _this = this;
      if (!_this.options.showMaxLine) {
        return;
      }
      const h = this.nodeHeight;
      let maxLine = svg
        .append("line")
        .attr("class", "max-line")
        .attr("stroke", _this.options.maxLineColor)
        .attr("stroke-width", _this.options.lineWeight)
        .attr("stroke-dasharray", "10,10")
        .attr("transform", "translate(0 ,0)")
        .attr("y1", 0)
        .attr("x1", X(max))
        .attr("y2", h)
        .attr("x2", X(max));
      svg
        .append("text")
        .attr("class", "label")
        .attr("font-size", 15)
        .attr("fill", _this.options.maxLineColor)
        .text(max)
        .attr("x", X(max))
        .attr("y", 23)
        .attr("transform", "translate(-" + 5 + "," + h + ")");
    },
    drawLinkExtension(svg, root) {
      const linkExtension = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .attr("stroke-opacity", 0.25)
        .selectAll("path")
        .data(root.filter((d) => !d.target.children))
        .enter()
        .append("path")
        .each(function (d) {
          d.target.linkExtensionNode = this;
        })
        .attr("stroke-dasharray", "5,5")
        .attr("stroke-width", this.options.lineWeight)
        .attr(
          "d",
          this.isSameLength
            ? this.linkExtensionConstant
            : this.linkExtensionVariable
        );
      return linkExtension;
    },
    drawLink(svg, root) {
      const link = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .selectAll("path")
        .data(root)
        .enter()
        .append("path")
        .each(function (d) {
          d.target.linkNode = this;
        })
        .attr("stroke-width", this.options.lineWeight)
        .attr("d", this.isSameLength ? this.linkConstant : this.linkVariable);
      // .attr("stroke", (d) => d.target.color);
      return link;
    },
    drawPoint(svg, root) {
      const _this = this;
      let point = _.find(this.groupTabs, (t) => t.show && t.style == "point");
      let color = null;
      if (point) {
        color = this.creatColorScale(point);
      }
      svg
        .append("g")
        .selectAll("circle")
        .data(root.filter((d) => !d.target.children))
        .enter()
        .append("circle")
        .attr("r", this.options.pointSize)
        .attr("fill", (d) => {
          const td = _.find(this.tableData, (t) => t.key == d.target.name);
          if (color && td) {
            return color(td[point.prop]);
          }
          return "#000";
        })
        .attr("cx", (d) => {
          return _this.isSameLength ? d.target.y : d.target.real_y;
        })
        .attr("cy", (d) => {
          return d.target.x;
        });
    },
    drawLength({ svg, root, X, max }) {
      if (!this.options.showLength) {
        return;
      }
      const textSvg = svg
        .append("g")
        .selectAll("text")
        .data(root)
        .enter()
        .append("text");

      const judgeIsFloat = (num) => {
        const a = parseInt(num.toString());
        const b = parseFloat(num.toString());
        return !(a == b);
      };
      const text = (d) => {
        if (
          d.length > 0 &&
          d.type != "leaf" &&
          d.real_y !== this.options.width
        ) {
          const num = Math.round((max - d.rootDist) * 100) / 100;
          return judgeIsFloat(num) ? num.toFixed(2) : num;
        }
        return "";
      };
      textSvg
        .attr("dy", ".11em")
        .attr("text-anchor", "end")
        .attr("transform", (d) => {
          return `translate(${X(max - d.rootDist) - 5},${d.x - 5})`;
        })
        .text(text);
    },
    drawText(svg, root) {
      if (!this.options.showLabel) {
        return;
      }
      const dd = root.filter((d) => d.type == "leaf");
      const _this = this;
      const text = (d) => {
        return _this.options.showNumInLabel
          ? `${d.name}(${d.length})`
          : `${d.name}`;
      };
      const w = this.computeExtendWidth();
      const textSvg = svg
        .append("g")
        .selectAll("text")
        .data(dd)
        .enter()
        .append("text");

      if (this.isSampleLabeWidth) {
        textSvg
          .attr("textLength", this.labelWidth)
          .attr("lengthAdjust", "spacingAndGlyphs");
      }
      textSvg
        .attr("dy", ".11em")
        .attr("font-size", this.options.fontSize)
        .attr("text-anchor", (d) => {
          if (_this.isLeft) {
            return "start";
          } else {
            return "end";
          }
        })
        .attr("transform", (d) => `translate(${d.y + 10 + w},${d.x})`)
        .text(text)
        .on("mouseover", this.mouseovered(true))
        .on("mouseout", this.mouseovered(false));
    },
    computedGroupLeft(item) {
      if (item.style == "rect") {
        let left = this.computeExtendWidth() + item.w + item.left;
        if (this.isSampleLabeWidth) {
          //40为平横参数，多次修改后最佳观感
          left = left + 40;
          if (!this.isLeft) {
            //右对齐，文字从外向内，需要减掉文字固定长度
            left = left - this.labelWidth;
          }
        } else {
          //20为平横参数，多次修改后最佳观感
          left = left + 20;
          if (this.isLeft) {
            left = left - 20;
          } else {
            //右对齐，减掉形状固定长度
            left = left - item.w;
          }
        }
        return left;
      } else if (item.style == "circle") {
        let left = this.computeExtendWidth() + item.r * 2 + item.left;
        if (this.isSampleLabeWidth) {
          left = left + 40;
          if (!this.isLeft) {
            //右对齐，文字从外向内，需要减掉文字固定长度
            left = left - this.labelWidth;
          }
        } else {
          left = left + 20;
          if (this.isLeft) {
            left = left;
          } else {
            //右对齐，减掉形状固定长度
            left = left - item.r;
          }
        }
        return left;
      }
    },

    creatRectGroup(svg, item, data) {
      if (item.style !== "rect") {
        return;
      }
      const _this = this;
      const color = this.creatColorScale(item);
      const left = this.computedGroupLeft(item);
      if (item.type == "more") {
        const { column, data: tableData } = item;
        column.forEach((c, i) => {
          const legend = svg.append("g");
          legend
            .selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("width", item.w)
            .attr("height", item.h)
            .attr("transform", (d, k) => {
              if (k == 0) {
                const text = legend
                  .append("text")
                  .attr("dy", ".11em")
                  .attr(
                    "transform",
                    `translate(${
                      d.y +
                      left +
                      (item.w + item.space) * i +
                      item.w / 2 -
                      (item.label.length * 6) / 2
                    },-10)`
                  )
                  .attr("font-weight", "bold")
                  .text(c);
                if (item.isVertical) {
                  text
                    .attr("writing-mode", "tb")
                    .attr("rotate", "-90")
                    .attr("text-anchor", "end")
                    .attr(
                      "transform",
                      `translate(${
                        d.y + left + (item.w + item.space) * i + item.w / 2 - 3
                      },-10)`
                    );
                  if (_this.hasChina(c)) {
                    text.attr("rotate", "0");
                  }
                }
              }
              return `translate(${d.y + left + (item.w + item.space) * i},${
                d.x - item.h / 2
              })`;
            })
            .attr("fill", (d) => {
              const td = _.find(tableData, (t) => t.key == d.name);
              if (td) return color(td[c]);
            });
        });
        return;
      }
      const legend = svg.append("g");
      legend
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("width", item.w)
        .attr("height", item.h)
        .attr("transform", (d, k) => {
          if (k == 0) {
            const text = legend
              .append("text")
              .attr("dy", ".11em")
              //文字X轴偏移量为数据y坐标+left(左延长线宽度)+元素一半宽-文字一半宽
              .attr(
                "transform",
                `translate(${
                  d.y + left + item.w / 2 - (item.label.length * 6) / 2
                },-10)`
              )
              .attr("font-weight", "bold")
              .text(item.label);

            if (item.isVertical) {
              text
                .attr("writing-mode", "tb")
                .attr("rotate", "-90")
                .attr("text-anchor", "end")
                .attr(
                  "transform",
                  `translate(${d.y + left + item.w / 2 - 3},-10)`
                );
              if (_this.hasChina(item.label)) {
                text.attr("rotate", "0");
              }
            }
          }
          return `translate(${d.y + left},${d.x - item.h / 2})`;
        })
        .attr("fill", (d) => {
          const td = _.find(this.tableData, (t) => t.key == d.name);
          if (td) return color(td[item.prop]);
        });
    },
    creatCircleGroup(svg, item, data) {
      if (item.style !== "circle") {
        return;
      }
      const _this = this;
      const color = this.creatColorScale(item);
      const left = this.computedGroupLeft(item);
      if (item.type == "more") {
        const { column, data: tableData } = item;
        column.forEach((c, i) => {
          const legend = svg.append("g");
          legend
            .selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("r", item.r)
            .attr("transform", (d, k) => {
              if (k == 0) {
                const text = legend
                  .append("text")
                  .attr("dy", ".11em")
                  //文字X轴偏移量为数据y坐标+left(左延长线宽度)-文字一半宽
                  .attr(
                    "transform",
                    `translate(${
                      d.y +
                      left +
                      (item.r * 2 + item.space) * i -
                      (item.label.length * 6) / 2
                    },-10)`
                  )
                  .attr("font-weight", "bold")
                  .text(c);
                if (item.isVertical) {
                  text
                    .attr("writing-mode", "tb")
                    .attr("rotate", "-90")
                    .attr("text-anchor", "end")
                    .attr(
                      "transform",
                      `translate(${
                        d.y + left + (item.r * 2 + item.space) * i - 3
                      },-10)`
                    );
                  if (_this.hasChina(c)) {
                    text.attr("rotate", "0");
                  }
                }
              }
              return `translate(${d.y + left + (item.r * 2 + item.space) * i},${
                d.x
              })`;
            })
            .attr("fill", (d) => {
              const td = _.find(tableData, (t) => t.key == d.name);
              if (td) return color(td[c]);
            });
        });
        return;
      }
      const legend = svg.append("g");

      legend
        .selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("r", item.r)
        .attr("transform", (d, k) => {
          if (k == 0) {
            let text = legend
              .append("text")
              .attr("dy", ".11em")
              //文字X轴偏移量为数据y坐标+left(左延长线宽度)-文字一半宽
              .attr(
                "transform",
                `translate(${d.y + left - (item.label.length * 6) / 2},-10)`
              )
              .attr("font-weight", "bold")
              .text(item.label);
            if (item.isVertical) {
              text
                .attr("writing-mode", "tb")
                .attr("rotate", "-90")
                .attr("transform", `translate(${d.y + left - 3},-10)`)
                .attr("text-anchor", "end");
              if (_this.hasChina(item.label)) {
                text.attr("rotate", "0");
              }
            }
          }
          return `translate(${d.y + left},${d.x})`;
        })
        .attr("fill", (d) => {
          const td = _.find(this.tableData, (t) => t.key == d.name);
          if (td) return color(td[item.prop]);
        });
    },
    drawGroup(svg, root) {
      let legends = _.filter(
        this.groupTabs,
        (t) => t.show && t.style != "point"
      );
      const dd = root.filter((d) => d.type == "leaf");
      const _this = this;
      for (let i in legends) {
        const item = legends[i];
        this.creatRectGroup(svg, item, dd);
        this.creatCircleGroup(svg, item, dd);
      }
    },
    drawLegend(svg, root, legends) {
      const dd = root.filter((d) => d.type == "leaf");
      const _this = this;
      for (let i in legends) {
        const { legendGroup, legendList, col, color, maxId } = legends[i];
        const legend = svg.append("g");
        legend
          .selectAll("rect")
          .data(dd)
          .enter()
          .append("rect")
          .attr("width", 50)
          .attr("height", 10)
          .attr("transform", (d, k) => {
            if (k == 0) {
              legend
                .append("text")
                .attr("dy", ".11em")
                .attr(
                  "transform",
                  `translate(0,-${
                    this.innerRadius + maxId.length * 7 + 30 + i * 80 + 75
                  })`
                )
                .text(col.label);
            }

            return `rotate(${d.x - 90}) translate(${
              this.innerRadius + maxId.length * 7 + 30 + i * 80 + 10
            },${d.x < 180 ? 3 : -3})`;
          })
          .attr("fill", (d) => {
            const td = _.find(this.tableData, (t) => t.key == d.name);
            if (td) return color(td[col.prop]);
          });
      }
    },
    linkVariable(d) {
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.real_y },
        { x: d.target.x, y: d.target.real_y }
      );
    },
    linkConstant(d) {
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.y },
        { x: d.target.x, y: d.target.y }
      );
    },
    computeExtendWidth() {
      let w = 0;
      if (this.isLeft) {
        w = this.expendLineWidth;
      } else {
        w = this.maxLableWidth + this.expendLineWidth;
        if (this.isSampleLabeWidth) {
          w = this.labelWidth + this.expendLineWidth;
        }
      }
      return w;
    },
    linkExtensionVariable(d) {
      let w = this.computeExtendWidth();
      if (this.isSampleLabeWidth) {
      } else if (!this.isLeft) {
        w = w - Number(d.target.name.length * this.textWidth);
      }
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.real_y },
        { x: d.target.x, y: d.target.y + w }
      );
    },
    linkExtensionConstant(d) {
      let w = this.computeExtendWidth();
      if (this.isSampleLabeWidth) {
      } else if (!this.isLeft) {
        w = w - Number(d.target.name.length * this.textWidth);
      }
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.y },
        { x: d.target.x, y: d.target.y + w }
      );
    },
    linkDiagonal(startpoint, endpoint) {
      let projection = function (d) {
        return [d.y, d.x];
      };
      let pathData = [
        { x: startpoint.x, y: startpoint.y },
        { x: endpoint.x, y: startpoint.y },
        { x: endpoint.x, y: endpoint.y },
      ];
      pathData = pathData.map(projection);
      return "M" + pathData[0] + " " + pathData[1] + " " + pathData[2];
    },

    drawTable(svg, root) {
      if (!this.tableOptions.showTable) {
        return;
      }
      const _this = this;
      const fontSize = this.options.fontSize;
      const d = root.filter((d) => d.type == "leaf");
      const columns = _.filter(this.columns, (t) => !t.hide);
      if (columns.length < 1) {
        return;
      }
      const data = this.tableData;
      const w = this.computedLeftCreat();

      for (let i in columns) {
        const c = columns[i];
        const textSvg = svg
          .append("g")
          .selectAll("text")
          .data(d)
          .enter()
          .append("text");

        textSvg
          .attr("dy", ".11em")
          .attr("font-size", fontSize)
          .attr("text-anchor", (d) => {
            return "start";
          })
          .attr(
            "transform",
            (d) =>
              `translate(${
                d.y +
                w +
                _this.tableOptions.left +
                ((150 * fontSize) / 9) * i +
                10
              },${d.x})`
          )
          .text((d, j) => {
            if (j == 0) {
              const thsvg = svg.append("g").append("text");
              thsvg
                .attr("dy", ".11em")
                .attr("font-size", fontSize)
                .attr("text-anchor", "start")
                .attr("font-size", this.options.fontSize)
                .attr(
                  "transform",
                  `translate(${
                    d.y +
                    w +
                    _this.tableOptions.left +
                    ((150 * fontSize) / 9) * i +
                    10
                  },${-10})`
                )
                .attr("font-weight", "bold")
                .text(c.label);
            }
            const td = _.find(this.tableData, (t) => t.key == d.name);
            if (td) {
              return td[c.prop];
            }
          });
      }
    },
  },
  computed: {
    expendLineWidth() {
      return this.options.expendLineWidth;
    },
    space() {
      return this.options.space;
    },
    isLeft() {
      return this.options.isLeft;
    },
    isSampleLabeWidth() {
      return this.options.isSampleLabeWidth;
    },
    isSameLength() {
      return this.options.isSameLength;
    },
    labelWidth() {
      return this.options.labelWidth;
    },
    showLength() {
      return this.options.showLength;
    },
    okData() {
      return this.dealNwk();
    },
    nodeHeight() {
      const { idlist } = this.okData;
      const h = idlist.length * (8 + this.space);
      return h;
    },
  },

  name: "rectangular-chart",
  mixins: [basemixin],
};
</script>

<style></style>
