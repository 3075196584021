<template>
  <page-box :height="boxHeight">
    <div :id="containerName" style="width: 100%; height: 100%"></div>
    <template #left>
      <el-collapse accordion v-model="activeName">
        <el-collapse-item name="1">
          <template slot="title"> <strong>文件</strong> </template>
          <el-button-group>
            <el-button type="primary" size="small" @click="savenwk"
              >另存为Newick</el-button
            >
            <el-button type="primary" size="small" @click="savesvg"
              >另存为SVG</el-button
            >
            <el-button type="primary" size="small" @click="savepdf"
              >另存为PDF</el-button
            >
          </el-button-group>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title"> <strong>基本设置</strong></template>
          <table>
            <tr>
              <th>标题：</th>
              <td>
                <el-input
                  v-model="ops.valueTitle"
                  size="small"
                  placeholder="请输入内容"
                ></el-input>
              </td>
            </tr>
            <tr>
              <th>标题位置：</th>
              <td>
                <el-input-number
                  v-model="ops.valueTitleLeft"
                  controls-position="right"
                  :min="50"
                  :max="width"
                  size="small"
                ></el-input-number>
                <!-- <el-slider
                  v-model="ops.valueTitleLeft"
                  size="small"
                  :min="50"
                  :max="width"
                  :show-tooltip="false"
                ></el-slider> -->
              </td>
            </tr>
            <tr>
              <th>最大值：</th>
              <td>
                <el-input-number
                  v-model="ops.maxValue"
                  controls-position="right"
                  :min="10"
                  size="small"
                ></el-input-number>
              </td>
            </tr>
            <tr>
              <th>最大值标识线：</th>
              <td>
                <el-radio-group v-model="ops.showMaxLine" size="small">
                  <el-radio-button :label="true">显示</el-radio-button>
                  <el-radio-button :label="false">隐藏</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr v-if="showMaxLine">
              <th>标识线颜色：</th>
              <td>
                <el-color-picker
                  size="small"
                  v-model="ops.maxLineColor"
                ></el-color-picker>
              </td>
            </tr>
            <tr>
              <th>宽度：</th>
              <td>
                <el-input-number
                  v-model="ops.width"
                  controls-position="right"
                  :min="60"
                  size="small"
                ></el-input-number>
              </td>
            </tr>
            <tr>
              <th>名称：</th>
              <td>
                <el-radio-group v-model="ops.showLabel" size="small">
                  <el-radio-button :label="true">显示</el-radio-button>
                  <el-radio-button :label="false">隐藏</el-radio-button>
                </el-radio-group>
              </td>
            </tr>

            <tr>
              <th>垂直间距：</th>
              <td>
                <el-input-number
                  v-model="ops.space"
                  controls-position="right"
                  :min="5"
                  size="small"
                ></el-input-number>
              </td>
            </tr>
            <tr>
              <th>节点大小：</th>
              <td>
                <el-input-number
                  v-model="ops.pointSize"
                  controls-position="right"
                  :min="3"
                  size="small"
                ></el-input-number>
              </td>
            </tr>
            <tr>
              <th>线条粗细：</th>
              <td>
                <el-input-number
                  v-model="ops.lineWeight"
                  controls-position="right"
                  :min="1"
                  size="small"
                ></el-input-number>
              </td>
            </tr>
            <tr>
              <th>数值辅助线：</th>
              <td>
                <el-radio-group v-model="ops.showGrid_Value" size="small">
                  <el-radio-button :label="true">显示</el-radio-button>
                  <el-radio-button :label="false">隐藏</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
            <tr>
              <th>名称辅助线：</th>
              <td>
                <el-radio-group v-model="ops.showGrid_Label" size="small">
                  <el-radio-button :label="true">显示</el-radio-button>
                  <el-radio-button :label="false">隐藏</el-radio-button>
                </el-radio-group>
              </td>
            </tr>
          </table>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title"> <strong>表格设置</strong></template>
          <div>
            <table>
              <tr>
                <td colspan="2">
                  <el-button type="primary" @click="setcloumns" size="small"
                    >列(显示|排序)</el-button
                  >
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <el-radio-group v-model="ops.showTabel" size="small">
                    <el-radio-button :label="true">显示</el-radio-button>
                    <el-radio-button :label="false">隐藏</el-radio-button>
                  </el-radio-group>
                </td>
              </tr>
              <tr>
                <th>左边距：</th>
                <td>
                  <el-input-number
                    v-model="ops.labelWidth"
                    controls-position="right"
                    :min="0"
                    size="small"
                  ></el-input-number>
                </td>
              </tr>
            </table>
          </div>
        </el-collapse-item>
        <el-collapse-item name="5">
          <template slot="title"> <strong>分组设置</strong></template>
          <div style="margin-bottom: 10px">
            <el-dropdown trigger="click">
              <el-button type="primary" size="small">
                添加数据分组<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(t, i) in column_All">
                  <el-dropdown-item :key="i" @click.native="addGroup(t)">{{
                    t.label
                  }}</el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
            &nbsp;
            <el-dropdown trigger="click">
              <el-button type="primary" size="small">
                添加固定分组<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <template v-for="(t, i) in group">
                  <el-dropdown-item :key="i" @click.native="addGroup_more(t)">{{
                    t.label
                  }}</el-dropdown-item>
                </template>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-tabs
            v-model="activeGroup"
            type="card"
            closable
            @tab-remove="removeGroup"
          >
            <el-tab-pane
              v-for="(item, index) in groupTabs"
              :key="index"
              :label="item.label"
              :name="item.prop"
            >
              <div>
                <el-radio-group v-model="item.show" size="small">
                  <el-radio-button :label="true">显示</el-radio-button>
                  <el-radio-button :label="false">隐藏</el-radio-button>
                </el-radio-group>
              </div>
              <table>
                <tr v-if="item.type == 'one'">
                  <th>标题：</th>
                  <td>
                    <el-input
                      v-model="item.label"
                      size="small"
                      placeholder="请输入内容"
                    ></el-input>
                  </td>
                </tr>

                <tr>
                  <th>标题：</th>
                  <td>
                    <el-radio-group v-model="item.isVertical" size="small">
                      <el-radio-button :label="true">垂直</el-radio-button>
                      <el-radio-button :label="false">水平</el-radio-button>
                    </el-radio-group>
                  </td>
                </tr>
                <tr v-if="item.isVertical">
                  <th>标题水平：</th>
                  <td>
                    <el-input-number
                      v-model="item.label_x"
                      controls-position="right"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr v-if="item.isVertical">
                  <th>标题垂直：</th>
                  <td>
                    <el-input-number
                      v-model="item.label_y"
                      controls-position="right"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr>
                  <th>形状：</th>
                  <td>
                    <el-select
                      v-model="item.style"
                      placeholder="请选择"
                      size="small"
                    >
                      <el-option label="矩形" value="rect"> </el-option>
                      <el-option label="圆" value="circle"> </el-option>
                      <el-option
                        label="点"
                        value="point"
                        v-if="item.type == 'one'"
                      >
                      </el-option>
                    </el-select>
                  </td>
                </tr>
                <tr v-if="item.style !== 'point'">
                  <th>左边距：</th>
                  <td>
                    <el-input-number
                      v-model="item.left"
                      controls-position="right"
                      :min="3"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr v-if="item.style == 'point'">
                  <th>大小：</th>
                  <td>
                    <el-input-number
                      v-model="item.point_r"
                      controls-position="right"
                      :min="3"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr v-if="item.style == 'circle'">
                  <th>半径：</th>
                  <td>
                    <el-input-number
                      v-model="item.r"
                      controls-position="right"
                      :min="5"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr v-if="item.style == 'rect'">
                  <th>长度：</th>
                  <td>
                    <el-input-number
                      v-model="item.w"
                      controls-position="right"
                      :min="5"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr v-if="item.style == 'rect'">
                  <th>宽度：</th>
                  <td>
                    <el-input-number
                      v-model="item.h"
                      controls-position="right"
                      :min="5"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
                <tr v-if="item.type == 'more'">
                  <th>水平间距：</th>
                  <td>
                    <el-input-number
                      v-model="item.space"
                      controls-position="right"
                      :min="0"
                      size="small"
                    ></el-input-number>
                  </td>
                </tr>
              </table>
            </el-tab-pane>
          </el-tabs>
        </el-collapse-item>
      </el-collapse>
    </template>
    <set-column :show.sync="show_set_column" :columns.sync="column_All" />
    <div :id="containerName + '1'" class="lx-legend-jtree-new"></div>
    <div class="lx-select-color" v-show="$refs.color && $refs.color.showPicker">
      <el-color-picker
        ref="color"
        v-model="color"
        :predefine="predefineColors"
        @change="color_change"
      >
      </el-color-picker>
    </div>
  </page-box>
</template>
<script>
import * as d3 from "d3";
import d3Tip from "d3-tip";
import _ from "lodash";
import { saveTextAsFile, saveAsPdf } from "../static/js/help";
import pageBox from "./page-container/index.vue";
import * as svgPanZoom from "svg-pan-zoom";

export default {
  watch: {
    ops: {
      handler(v, ov) {
        const _this = this;
        _this.loading = true;
        window.setTimeout(function () {
          _this.clear();
          _this.init();
        }, 20);
      },
      deep: true,
    },
    groupTabs: {
      handler(v, ov) {
        const _this = this;
        _this.loading = true;
        window.setTimeout(function () {
          _this.clear();
          _this.init();
        }, 20);
      },
      deep: true,
    },
    columns: {
      handler(v, ov) {
        this.column_All = v;
      },
      immediate: true,
    },
    column: {
      handler(v) {
        const _this = this;
        _this.loading = true;
        window.setTimeout(function () {
          if (_this.ops.showTabel) {
            _this.clear();
            _this.init();
          }
        }, 20);
      },
      deep: true,
    },
    max: {
      handler(v) {
        this.ops.maxValue = v;
      },
      immediate: true,
    },
    defaultShowTable: {
      handler(v) {
        this.ops.showTabel = v;
      },
      immediate: true,
    },
  },
  props: {
    boxHeight: {
      type: String,
      default: "90vh",
    },
    max: {
      type: Number,
      default: 10,
    },
    defaultShowTable: {
      type: Boolean,
      default: false,
    },
    defaultColumnWidth: {
      type: Number,
      default: 150,
    },
    containerName: {
      type: String,
      default: "xin-jl-chart",
    },
    nwk: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            prop: "key",
            label: "Key",
          },
        ];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    group: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultGroup: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {},
  mounted() {
    if (!this.nwk || this.nwk.indexOf("(") < 0) {
      console.log("数据格式不合法");
      return;
    }
    this.dataInit();
    this.creatDefaultGroup();
    this.clear();
    this.init();
  },
  methods: {
    dealTableData(lableAxis) {
      const res = _.filter(this.tableData, (t) =>
        _.some(lableAxis, (x) => x.trim() === t.key.trim())
      );
      return res;
    },
    creatDefaultGroup() {
      const _this = this;
      this.defaultGroup.forEach((t) => {
        const c = _.find(_this.column_All, (c) => c.prop == t);
        if (c) {
          _this.addGroup(c);
        }
        const g = _.find(_this.group, (c) => c.prop == t);
        if (g) {
          _this.addGroup_more(g);
        }
      });
    },
    color_change(v) {
      this.showcolor = false;
      const { color, prop } = this.clickColor;
      const i = _.findIndex(this.groupTabs, (t) => t.prop == prop);
      if (i >= 0) {
        let cc = this.groupTabs[i].colors;
        const ci = cc.indexOf(color);
        cc[ci] = v;
        this.groupTabs[i].colors = [].concat(cc);
      }
    },
    creatLegends() {
      const _this = this;
      let main = d3.select("#" + this.containerName + "1");
      d3.selectAll("#lx-svg-legend-box").remove();
      d3.selectAll("#lx-svg-legend").remove();
      const svg_box = main
        .append("svg")
        .attr("id", "lx-svg-legend-box")
        .attr("width", 200);
      const svg = svg_box
        .append("g")
        .attr("id", "lx-svg-legend")
        .attr("width", 200);
      let height = 0;
      const legends = _.filter(this.groupTabs, (t) => t.show);
      if (legends.length < 1) {
        return;
      }
      for (let i in legends) {
        const { type, style, group, colors, label, column, prop } = legends[i];
        const d = legends[i];
        const legendList = Object.keys(group);
        const color = this.creatColorScale(d);
        const legend = svg.append("g").attr("class", "lx-svg-legend");
        const g = legend
          .selectAll("g")
          .data(color.domain())
          .enter()
          .append("g")
          .attr("transform", (d, i) => {
            return `translate(9,${(i + 1) * 20 + height})`;
          });

        const clickfun = (d) => {
          const _color = color(d);
          _this.clickColor = {
            color: _color,
            prop,
          };
          _this.$refs.color.showPicker = true;
        };
        if (style == "rect") {
          const rect = g
            .append("rect")
            .attr("width", 18)
            .attr("height", 18)
            .attr("fill", (d) => {
              return color(d);
            });
          rect.on("click", null);
          rect.on("click", clickfun);
        }
        if (style == "circle") {
          const circle = g
            .append("circle")
            .attr("r", 9)
            .attr("cx", 9)
            .attr("cy", 9)
            .attr("fill", (d) => {
              return color(d);
            });
          circle.on("click", null);
          circle.on("click", clickfun);
        }

        if (style == "point") {
          const point = g
            .append("circle")
            .attr("r", 5)
            .attr("cx", 9)
            .attr("cy", 9)
            .attr("fill", (d) => {
              return color(d);
            });
          point.on("click", null);
          point.on("click", clickfun);
        }

        g.append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text((d) => d);
        legend
          .append("g")
          .attr("transform", `translate(0,${height})`)
          .append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text(label);
        height = height + legendList.length * 20 + 30;
      }
      svg_box.attr("height", height + 30);
      svg.attr("height", height + 30);
    },
    setcloumns() {
      this.show_set_column = true;
    },
    bindZoom() {
      window.zoomTiger = svgPanZoom("#lx_jtree_svg_" + this.containerName, {
        zoomEnabled: true,
        controlIconsEnabled: false,
        fit: true,
        center: true,
        minZoom: 0.08,
        maxZoom: 100,
      });
    },
    computedLeftCreat() {
      const dd = _.filter(this.groupTabs, (t) => t.style != "point" && t.show);
      const maxLeft = _.maxBy(dd, (t) => t.left);
      const now = this.width + this.left + this.labelWidth;
      if (!maxLeft) {
        return now;
      }
      const { type, style, left, w, r, space, column } = maxLeft;
      if (type == "one" && style == "rect") {
        return left + w + space;
      }
      if (type == "one" && style == "circle") {
        return left + r * 2 + space;
      }
      if (type == "more" && style == "rect") {
        return left + (w + space) * column.length + 5;
      }
      if (type == "more" && style == "circle") {
        return left + (2 * r + space) * column.length + 5;
      }
    },
    addGroup(t) {
      let newTabName = t.prop;
      if (!_.some(this.groupTabs, (g) => g.prop == newTabName)) {
        const group = _.groupBy(this.selectTable, (s) => s[newTabName]);
        const colors = this.creatColors(Object.keys(group));

        const left = this.computedLeftCreat();
        this.groupTabs.push({
          label: t.label,
          prop: newTabName,
          type: "one",
          isVertical: false,
          label_x: 0,
          label_y: 0,
          w: 80,
          h: 28,
          r: 13,
          space: 5,
          point_r: 5,
          left,
          show: true,
          style: "rect", //rect,point,circle
          colors: t.colors ? t.colors : colors,
          group: t.group ? t.group : Object.keys(group),
        });
        this.activeGroup = newTabName;
      }
    },
    addGroup_more(t) {
      let newTabName = t.prop;
      if (!_.some(this.groupTabs, (g) => g.prop == newTabName)) {
        const groupData = _.find(this.group, (r) => r.prop == newTabName);
        const data = groupData.data;
        const columns = Object.keys(data[0]);
        let group = [],
          column = [];
        columns.forEach((c) => {
          if (c != "key") {
            const _group = _.groupBy(data, (d) => d[c]);
            group = _.concat(group, Object.keys(_group));
            column.push(c);
          }
        });
        group = t.group ? t.group : _.uniq(group);
        const colors = t.colors
          ? t.colors
          : this.creatColors(Object.keys(group));

        const left = this.computedLeftCreat();
        this.groupTabs.push({
          label: t.label,
          prop: newTabName,
          type: "more",
          isVertical: false,
          label_x: 0,
          label_y: 0,
          w: 80,
          h: 28,
          r: 13,
          space: 1,
          left,
          show: true,
          data,
          style: "rect", //rect,circle
          colors,
          group,
          column,
        });
        this.activeGroup = newTabName;
      }
    },
    removeGroup(targetName) {
      let tabs = this.groupTabs;
      let activeName = this.activeGroup;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.prop === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.prop;
            }
          }
        });
      }

      this.activeGroup = activeName;
      this.groupTabs = tabs.filter((tab) => tab.prop !== targetName);
    },
    savenwk() {
      saveTextAsFile(this.nwk, "Jtree.nwk");
    },
    savesvg() {
      var res = this.getSVG();
      saveTextAsFile(res.svg, "Jtree.svg");
    },
    getSVG() {
      const size = window.zoomTiger.getSizes();
      const room = size.realZoom;

      let svg = document.getElementById("lx_jtree_svg_" + this.containerName);
      let leg = document.getElementById("lx-svg-legend");
      const leghtml = leg.outerHTML;
      var ori_pos = document.getElementById(this.containerName + "1");
      leg.setAttribute(
        "transform",
        "translate(" + ori_pos.offsetLeft + "," + ori_pos.offsetTop + ")"
      );
      svg.appendChild(leg);
      const _height = leg.getAttribute("height");
      svg.setAttribute("height", _height < size.height ? size.height : _height);
      const svgData = document.getElementById(
        "lx_jtree_svg_" + this.containerName
      ).outerHTML;
      const w = svg.clientWidth;
      let h = svg.clientHeight;
      if (this.height > h) {
        h = this.height;
      }

      leg.setAttribute("transform", "translate(0,0)");
      document.getElementById("lx-svg-legend-box").appendChild(leg);
      const svgDataOK = ["<svg ", svgData.substring(5, 9999999)].join("");
      return {
        svg: svgDataOK,
        w,
        h,
      };
    },
    savepdf() {
      let res = this.getSVG();
      if (this.$listeners["savepdf"]) {
        this.$emit("savepdf", { svg: res.svg, w: res.w, h: res.h });
      } else {
        saveAsPdf(res.svg, "Jtree", res.w, res.h);
      }
    },
    countWidth() {
      if (this.column.length >= 1) {
        const cc = [];
        for (const i in this.column) {
          const t = this.column[i];
          const max = d3.max(this.selectTable, (d) => {
            const l = t.label.length + 1;
            const ss = d[t.prop];
            if (!ss) {
              return l;
            }
            const l_all = ss.length;
            const en = String(ss).replace(/[\u4e00-\u9fa5]/g, "");
            const l_en = en.length;
            const l_cn = l_all - l_en;
            const l_end = l_cn + (l_en + 1) * 0.75;
            if (l < l_end) return l_end;
            return l;
          });

          cc.push({ ...t, width: (max * 50) / 3 });
        }
        this._columns = cc;
      }
      if (this.column.length >= 1) {
        const defaultw = this.defaultColumnWidth;
        this.rightWidth =
          100 +
          _.sumBy(this._columns, (t) => {
            return t.width || defaultw;
          });
      } else {
        this.rightWidth = this.labelWidth;
      }
      this.allWidth = this.rightWidth + this.width;
    },
    getLabel() {
      return this.lableAxis;
    },
    clear() {
      // this.showData = [];
      d3.selectAll("svg > *").remove();
      d3.select("svg").remove();
    },
    dataInit() {
      console.time("01");
      this.countWidth();
      console.timeEnd("01");

      console.time("04");
      this.countHeight(this.lableAxis.length);
      console.timeEnd("04");
    },
    init() {
      const _this = this;
      if (this.showData.length < 1) {
        _this.loading = false;
        return;
      }
      console.time("a");
      console.time("0");
      _this.dataInit();
      console.timeEnd("0");
      console.time("1");
      let svg = _this.drawSvg();
      const X = _this.drawValueAxis(svg);
      const Y = _this.drawlabelAxis(svg);
      _this.drawMaxLine(svg, X, Y);
      console.timeEnd("1");
      console.time("2");
      _this.drawPath(_this.showData, svg, X, Y);
      console.timeEnd("2");
      console.time("3");
      _this.drawPoint(_this.showData, svg, X, Y);

      if (_this.showGrid_Label) {
        _this.drawGridLabel();
      }
      if (_this.showGrid_Value) {
        _this.drawGridValue();
      }
      _this.drawLabelPoint(svg, X, Y);
      console.timeEnd("3");
      console.time("4");
      _this.drawGroup(svg, Y, _this.groupTabs);
      console.timeEnd("4");
      console.time("5");
      _this.drawTable(svg, _this.XAxis, Y);
      console.timeEnd("5");
      console.time("6");
      _this.bindZoom();
      console.timeEnd("6");
      console.time("7");
      _this.creatLegends();
      console.timeEnd("7");
      console.timeEnd("a");
      _this.loading = false;
    },
    drawPath(data, svg, X, Y) {
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      let line = d3.svg
        .line()
        .x(function (d) {
          return X(d.x);
        })
        .y(function (d) {
          return (Y(d.y1) + Y(d.y2)) / 2;
        });
      const color = this.lineColor;
      let serie = svg
        .selectAll(".serie") // 生成线条
        .data(data)
        .enter()
        .append("g")
        .attr("class", "serie");
      serie
        .append("path") // 绘画线条
        .attr("class", "line")
        .attr("stroke", color)
        .attr("stroke-width", this.lineWeight)
        .attr("fill", "none")
        .attr("d", line)
        .attr("transform", "translate(" + this.left + "," + h + ")");
    },
    dealNwkData(data) {
      let list = {};
      let res = [];
      const getv = (str) => {
        if (!str) {
          return [0, 0];
        }
        const value = _.map(
          str.match(/:([0-9]{1,})?(\.[0-9]{1,})?|:([0-9]{1,})/g),
          (t) => Number(t.replace(":", ""))
        );
        return value;
      };
      const getmax = (str) => {
        if (!str) {
          return 0;
        }
        const value = getv(str);
        const max = d3.max(value);
        return max;
      };
      const getmin = (str) => {
        if (!str) {
          return 0;
        }
        const value = getv(str);
        const min = d3.min(value);
        return min;
      };
      let fun = (a) => {
        const s = a.lastIndexOf("(") + 1;
        const e = a.substring(s).indexOf(")") + s;
        //不带括号字符串
        let str = a.substring(s, e);
        //带括号字符串
        const str1 = a.substring(s - 1, e + 1);
        //合成名字
        const name = str
          .replace(/:([0-9]{1,})?(\.[0-9]{1,})?|:([0-9]{1,})/g, "")
          .replace(",", "***");
        const names = str.replace(
          /:([0-9]{1,})?(\.[0-9]{1,})?|:([0-9]{1,})/g,
          ""
        );
        let name1 = names.split(",")[0];

        const max1 = getmax(list[name1]);

        let name1arr = name1.split("***");

        let name2 = names.split(",")[1];
        const max2 = getmax(list[name2]);

        let name2arr = name2.split("***");

        const max12 = d3.max([max1, max2]);
        let v = getv(str);
        v[0] = v[0] + max1;
        v[1] = v[1] + max2;
        let max = d3.max(v);
        str = `${name1}:${max},${name2}:${max}`;
        if (max > this.maxValue) {
          max = this.maxValue;
        }
        res.push([name1arr, name2arr, max, name1arr.length + name2arr.length]);
        list[name] = str;
        const ss = a.replace(str1, name);
        if (ss.indexOf(",") >= 0) {
          fun(ss);
        }
      };
      fun(data);
      // this.JLData = res;
      // this.dealRealData(res);
      return res;
    },
    dealRealData(data) {
      let xx = [];
      let res = [];
      for (var i in data) {
        const t = data[i];
        const t0 = t[0];
        const t1 = t[1];
        let dd = [];

        if (t[3] == 2) {
          dd.push({ x: 0, y1: t0[0], y2: t0[0] });
          dd.push({ x: t[2], y1: t0[0], y2: t0[0] });
          dd.push({ x: t[2], y1: t1[0], y2: t1[0] });
          dd.push({ x: 0, y1: t1[0], y2: t1[0] });
          xx.push(t0[0]);
          xx.push(t1[0]);
        } else {
          const t0l = t0.length;
          const t1l = t1.length;
          if (t0l >= 2) {
            const dd0 = _.find(
              data,
              (d) => d[3] == t0l && d[0].indexOf(t0[0]) >= 0
            );
            dd.push({ x: dd0[2], y1: t0[0], y2: t0[t0l - 1] });
            dd.push({ x: t[2], y1: t0[0], y2: t0[t0l - 1] });
          } else {
            dd.push({ x: 0, y1: t0[0], y2: t0[0] });
            dd.push({ x: t[2], y1: t0[0], y2: t0[0] });
            xx.push(t0[0]);
          }
          if (t1l >= 2) {
            const dd1 = _.find(
              data,
              (d) => d[3] == t1l && d[0].indexOf(t1[0]) >= 0
            );
            dd.push({ x: t[2], y1: t1[0], y2: t1[t1l - 1] });
            dd.push({ x: dd1[2], y1: t1[0], y2: t1[t1l - 1] });
          } else {
            dd.push({ x: t[2], y1: t1[0], y2: t1[0] });
            dd.push({ x: 0, y1: t1[0], y2: t1[0] });
            xx.push(t1[0]);
          }
        }
        res.push(dd);
      }
      this.lableAxis = xx;
      this.XAxis = xx;
      return res;
    },
    countHeight(lableCount) {
      this.height = this.space * lableCount + this.top;
    },
    drawSvg() {
      let main = d3.select("#" + this.containerName);
      const viewBox = [200, -100, 1000, 1000];
      let svg = main
        .append("svg")
        .attr("viewBox", viewBox)
        .attr("id", "lx_jtree_svg_" + this.containerName)
        .attr("width", "100%")
        .attr("height", "100%");
      return svg;
    },
    drawValueAxis(svg) {
      const X = d3.scale
        .linear()
        .domain([0, this.maxValue])
        .rangeRound([this.xLength(), 0]);
      const axisX = d3.svg.axis().scale(X).orient("top").ticks(10);

      svg
        .append("g")
        .call(axisX)
        .attr("transform", "translate(" + this.left + "," + this.top + ")")
        .attr("class", "axis axis--x");
      d3.select(".axis--x")
        .select("path")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("fill", "none");
      return X;
    },
    drawlabelAxis(svg) {
      const Y = d3.scale
        .ordinal()
        .domain(this.XAxis)
        .rangeBands([0, this.yLength()]);
      const axisY = d3.svg.axis().scale(Y).orient("right");
      if (!this.showLabel) {
        axisY.tickFormat(function () {
          return "";
        });
      }
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      svg
        .append("g")
        .call(axisY)
        .attr(
          "transform",
          "translate(" + (this.left + this.width - 10) + "," + this.top + ")"
        )
        .attr("class", "axis axis--y")
        .append("text")
        .attr("y", -35)
        .attr(
          "x",
          -(this.allWidth - this.rightWidth + this.left - this.valueTitleLeft)
        )
        // .attr("dy", ".71em")
        .style("text-anchor", "middle")
        .attr("fill", "#000")
        .text(this.valueTitle);

      d3.select(".axis--y").select("path").remove();
      // .attr("stroke", "#000")
      // .attr("stroke-width", 1)
      // .attr("fill", "none");
      return Y;
    },
    creatColors(data) {
      let color = [];
      for (let i in data) {
        color.push("#" + Math.floor(Math.random() * 0xffffff).toString(16));
      }
      return color;
    },
    creatColorScale(data) {
      const range = data.colors;
      let color = d3.scale.ordinal().domain(data.group).range(range);
      return color;
    },
    drawGroup(svg, Y, data) {
      for (let i in data) {
        const d = data[i];
        if (!d.show || d.style == "point") {
          continue;
        }
        if (d.type == "one") {
          const { prop, label, w, h, r, left, style } = d;

          const range = style == "rect" ? [0, w] : [0, 2 * r];
          const X = d3.scale.ordinal().domain([label]).rangeBands(range);
          const axisX = d3.svg.axis().scale(X).orient("top");

          const color = this.creatColorScale(d);

          svg
            .append("g")
            .call(axisX)
            .attr("transform", "translate(" + left + "," + this.top + ")")
            .attr("class", "axis axis--x2_" + prop);
          d3.select(".axis--x2_" + prop)
            .select("path")
            .remove();
          // .attr("stroke", "black")
          // .attr("stroke-width", 1)
          // .attr("fill", "none");

          if (d.isVertical) {
            d3.select(".axis--x2_" + prop)
              .select("text")
              .attr("writing-mode", "tb")
              .attr("rotate", "-90")
              .attr("letter-spacing", 4)
              .attr("x", d.label_x)
              .attr("y", d.label_y)
              .style("text-anchor", "end");
          }

          if (style == "rect") {
            d3.select(".axis--x2_" + prop)
              .selectAll("rect")
              .data(this.selectTable)
              .enter()
              .append("rect")
              .attr("width", w)
              .attr("height", h)
              .attr("transform", (d, k) => {
                return `translate(${X(label)},${
                  Y(d.key) + this.space / 2 - h / 2
                })`;
              })
              .attr("fill", (d) => {
                return color(d[prop]);
              });
          } else if (style == "circle") {
            d3.select(".axis--x2_" + prop)
              .selectAll("circle")
              .data(this.selectTable)
              .enter()
              .append("circle")
              .attr("r", r)
              .attr("transform", (d, k) => {
                return `translate(${X(label) + r},${
                  Y(d.key) + this.space / 2
                })`;
              })
              .attr("fill", (d) => {
                return color(d[prop]);
              });
          }
        } else if (d.type == "more") {
          const { prop, label, w, h, r, left, style, column, space } = d;
          const _data = d.data;
          const range =
            style == "rect"
              ? [0, (w + space) * column.length]
              : [0, (2 * r + space) * column.length];
          const X = d3.scale.ordinal().domain(column).rangeBands(range);
          const axisX = d3.svg.axis().scale(X).orient("top");
          const color = this.creatColorScale(d);

          svg
            .append("g")
            .call(axisX)
            .attr("transform", "translate(" + left + "," + this.top + ")")
            .attr("class", "axis axis--x2_" + prop);
          d3.select(".axis--x2_" + prop)
            .select("path")
            .remove();
          // .attr("stroke", "black")
          // .attr("stroke-width", 1)
          // .attr("fill", "none");
          if (d.isVertical) {
            d3.select(".axis--x2_" + prop)
              .selectAll("text")
              .attr("rotate", "-90")
              .attr("writing-mode", "tb")
              .attr("letter-spacing", 4)
              .attr("x", d.label_x)
              .attr("y", d.label_y)
              .style("text-anchor", "end");
          }

          if (style == "rect") {
            d3.select(".axis--x2_" + prop)
              .selectAll(".tick")
              .selectAll("rect")
              .data(_data)
              .enter()
              .append("rect")
              .attr("width", w)
              .attr("height", h)
              .attr("transform", (d, i, c) => {
                return `translate(${-w / 2},${
                  Y(d.key) + this.space / 2 - h / 2
                })`;
              })
              .attr("fill", (d, i, c) => {
                return color(d[column[c]]);
              });
          } else if (style == "circle") {
            d3.select(".axis--x2_" + prop)
              .selectAll(".tick")
              .selectAll("circle")
              .data(_data)
              .enter()
              .append("circle")
              .attr("r", r)
              .attr("transform", (d, i, c) => {
                return `translate(0,${Y(d.key) + this.space / 2})`;
              })
              .attr("fill", (d, i, c) => {
                return color(d[column[c]]);
              });
          }
        }
      }
    },
    drawTableColumn(svg) {
      if (!this.ops.showTabel) {
        return;
      }
      if (this.column.length < 1) {
        return;
      }
      // let last_w = this.left + this.allWidth - this.rightWidth + 10;
      let last_w = this.computedLeftCreat() + 10;
      const fun = (t, i, w) => {
        //定义已经使用的宽度
        const _w = w;
        //定义列宽
        const _cw = t.width || this.defaultColumnWidth;
        const X = d3.scale.ordinal().domain([t.label]).rangeBands([0, _cw]);
        const axisX = d3.svg.axis().scale(X).orient("top");
        svg
          .append("g")
          .call(axisX)
          .attr("transform", "translate(" + _w + "," + this.top + ")")
          .attr("class", "axis axis--x2_" + i);
        d3.select(".axis--x2_" + i)
          .select("path")
          .remove();
        // .attr("stroke", "black")
        // .attr("stroke-width", 1)
        // .attr("fill", "none");
        d3.select(".axis--x2_" + i)
          .select("g")
          .attr("transform", "translate(0,0)");
        d3.select(".axis--x2_" + i)
          .select("g")
          .append("rect")
          .attr("class", "lx-table-header")
          .attr("width", i == 0 ? _cw + 10 : _cw)
          .attr("height", 26)
          .attr("fill", "rgb(0, 0, 0)")
          .attr("fill-opacity", 0.1)
          .attr("strokeWidth", 0)
          // .attr("stroke", "rgb(0, 0, 0)")
          .attr("transform", "translate(" + (i == 0 ? -10 : 0) + ",-26)");
        d3.select(".axis--x2_" + i)
          .select("text")

          .style("text-anchor", "start");
        // .style("padding-left", "5px");
        last_w = _w + _cw;
      };
      for (const i in this._columns) {
        let t = this._columns[i];
        fun(t, i, last_w);
      }
    },
    drawTableData(svg, Y) {
      if (!this.ops.showTabel) {
        return;
      }
      if (this.column.length < 1 || this.selectTable.length < 1) {
        return;
      }
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      // const rightWidth = this.allWidth - this.labelWidth + this.left + 5;
      const rightWidth = this.computedLeftCreat() + this.rightWidth - 100;
      // const leftWidth = this.allWidth - this.rightWidth + 10;
      const leftWidth = this.computedLeftCreat();

      const fun = (d1, i1) => {
        const y = Y(d1.key);

        let last_w = this.computedLeftCreat() + 10;
        const fun_c = (t, d, i, w) => {
          //定义已经使用的宽度
          const _w = w;
          //定义列宽
          const _cw = t.width || this.defaultColumnWidth;
          const X = d3.scale.ordinal().domain([d]).rangeBands([0, _cw]);
          const axisX = d3.svg.axis().scale(X).orient("top");
          svg
            .append("g")
            .call(axisX)
            .attr(
              "transform",
              "translate(" + _w + "," + (this.top + y - 4 + h / 2) + ")"
            )
            .attr("class", "axis axis--x23_" + i1 + "_" + i);
          d3.select(".axis--x23_" + i1 + "_" + i)
            .select("path")
            .remove();
          // .attr("stroke", "black")
          // .attr("stroke-width", 1)
          // .attr("fill", "none");
          d3.select(".axis--x23_" + i1 + "_" + i)
            .select("g")
            .on("click", function () {
              const obj = d3
                .select(".axis--x23_" + i1 + "_" + 0)
                .select("rect")[0][0];
              if (!!obj) {
                d3.selectAll(".lx-table-data").remove();
                return;
              }
              d3.selectAll(".lx-table-data").remove();
              d3.select(".axis--x23_" + i1 + "_" + 0)
                .select("g")
                .append("rect")
                .attr("class", "lx-table-data")
                .attr("width", rightWidth)
                .attr("height", 26)
                .attr("fill", "rgb(0, 0, 255)")
                .attr("fill-opacity", 0.1)
                .attr("strokeWidth", 0)
                // .attr("stroke", "rgb(0, 0, 0)")
                .attr("transform", "translate(-" + leftWidth + ",-26)");
            })
            .attr("transform", "translate(" + 0 + ",0)");
          d3.select(".axis--x23_" + i1 + "_" + i)
            .select("text")
            .style("text-anchor", "start");
          // .attr("stroke", "black")
          // .attr("stroke-width", 1)
          // .attr("fill", "none");
          last_w = _w + _cw;
        };
        const colum = _.map(this._columns, (t) => d1[t.prop] || "-");
        for (const i in this._columns) {
          let t = this._columns[i];
          fun_c(t, colum[i], i, last_w);
        }
      };

      const data = this.selectTable;
      for (let i in data) {
        const t = data[i];
        fun(t, i);
      }
    },
    drawTable(svg, root, Y) {
      if (!this.ops.showTabel) {
        return;
      }
      if (this.column.length < 1 || this.selectTable.length < 1) {
        return;
      }

      const _this = this;
      const d = root;
      const columns = this.column;
      if (columns.length < 1) {
        return;
      }
      const data = this.selectTable;
      const w = this.computedLeftCreat();

      for (let i in columns) {
        const c = columns[i];
        const textSvg = svg
          .append("g")
          .selectAll("text")
          .data(d)
          .enter()
          .append("text");

        textSvg
          .attr("dy", ".11em")
          .attr("text-anchor", (d) => {
            return "start";
          })
          .attr(
            "transform",
            (d) =>
              `translate(${w + 200 * i + 10},${
                Y(d) + _this.top + _this.space / 2
              })`
          )
          .text((d, j) => {
            if (j == 0) {
              const thsvg = svg.append("g").append("text");
              thsvg
                .attr("dy", ".11em")
                .attr("text-anchor", "start")
                .attr(
                  "transform",
                  `translate(${w + 200 * i + 10},${-10 + _this.top})`
                )
                .attr("font-weight", "bold")
                .text(c.label);
            }
            const td = _.find(data, (t) => t.key == d);
            if (td) {
              return td[c.prop];
            }
          });
      }
    },
    drawMaxLine(svg, X, Y) {
      if (!this.showMaxLine) {
        return;
      }
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      const max = d3.max(this.JLData, (t) => t[2]);
      let maxLine = svg
        .append("line")
        .attr("class", "max-line")
        .attr("stroke", this.maxLineColor)
        .attr("stroke-width", this.lineWeight)
        .attr("stroke-dasharray", "10,10")
        .attr("transform", "translate(" + this.left + "," + this.top + ")")
        .attr("y1", 0)
        .attr("x1", X(max))
        .attr("y2", this.yLength() - 2 * h)
        .attr("x2", X(max));
      svg
        .append("text")
        .attr("class", "label")
        .attr("font-size", 15)
        .attr("fill", this.maxLineColor)
        .text(max)
        .attr("x", X(max + 0.5))
        .attr("y", 23)
        .attr(
          "transform",
          "translate(" +
            this.left +
            "," +
            h +
            ")rotate(-90," +
            X(max + 0.5) +
            ",23)"
        );
    },
    get_max() {
      const max = d3.max(this.JLData, (t) => t[2]);
      return max;
    },
    drawPoint(data, svg, X, Y) {
      const textX = 0.3;
      const textY = -5;
      const _this = this;
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      const max = d3.max(this.JLData, (t) => t[2]);
      var node = svg
        .selectAll(".node")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "node");

      // node
      //   .append("circle")
      //   .attr("r", this.pointSize)
      //   .attr("fill", this.pointColor)
      //   .attr("transform", "translate(" + this.left + "," + h + ")")
      //   // .style("z-index", "10")
      //   .attr("cx", function (d) {
      //     const _min = d3.min(d, (t) => t.x);
      //     if (_min == 0 || _min == max) {
      //       return -10;
      //     }
      //     return X(_min);
      //   })
      //   .attr("cy", function (d) {
      //     const _min = d3.min(d, (t) => t.x);
      //     const dmin = _.filter(d, (t) => t.x == _min);
      //     return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2;
      //   });

      node
        .append("text")
        .attr("class", "label")
        .attr("font-size", 10)
        // .style("transform", "translate(" + this.left + "px," + h + "px)")
        .text(function (d) {
          const _min = d3.min(d, (t) => t.x);
          if (_min == 0 || _min == max) {
            return "";
          }
          return _min;
        })
        .attr("x", function (d) {
          const _min = d3.min(d, (t) => t.x);
          return X(_min + textX);
        })
        .attr("y", function (d) {
          const _min = d3.min(d, (t) => t.x);
          const dmin = _.filter(d, (t) => t.x == _min);
          return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY;
        })
        .attr("transform", function (d) {
          const _min = d3.min(d, (t) => t.x);
          // const _min = d3.min(d, (t) => t.x);
          const dmin = _.filter(d, (t) => t.x == _min);
          const str =
            "translate(" +
            _this.left +
            "," +
            h +
            ")rotate(-90," +
            X(_min + textX) +
            "," +
            ((Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY) +
            ")";
          return str;
        });

      // node
      //   .append("circle")
      //   .attr("r", this.pointSize)
      //   .attr("fill", this.pointColor)
      //   .attr("transform", "translate(" + this.left + "," + h + ")")
      //   // .style("z-index", "10")
      //   .attr("cx", function (d) {
      //     const _max = d3.max(d, (t) => t.x);
      //     const _min = d3.min(d, (t) => t.x);
      //     const dd = _.filter(d, (t) => t.x > _min && t.x < _max);

      //     if (dd && dd.length > 0) {
      //       if (dd[0].x == 0 || dd[0].x == max) {
      //         return 0;
      //       }
      //       return X(dd[0].x);
      //     }
      //     if (_min == 0 || _min == max) {
      //       return 0;
      //     }
      //     return X(_min);
      //   })
      //   .attr("cy", function (d) {
      //     const _max = d3.max(d, (t) => t.x);
      //     const _min = d3.min(d, (t) => t.x);
      //     const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
      //     if (dd && dd.length > 0) {
      //       return (Y(dd[0].y1) + Y(dd[0].y2)) / 2;
      //     }
      //     const dmin = _.filter(d, (t) => t.x == _min);
      //     if (dmin.length > 1) {
      //       return (Y(dmin[1].y1) + Y(dmin[1].y2)) / 2;
      //     }
      //     return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2;
      //   });

      node
        .append("text")
        .attr("class", "label")
        .attr("font-size", 10)
        .text(function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return dd[0].x;
          }
          if (_min == 0 || _min == max) {
            return "";
          }
          return _min;
        })
        .attr("x", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return X(dd[0].x + textX);
          }
          return X(_min + textX);
        })
        .attr("y", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return (Y(dd[0].y1) + Y(dd[0].y2)) / 2 + textY;
          }
          const dmin = _.filter(d, (t) => t.x == _min);
          if (dmin.length > 1) {
            return (Y(dmin[1].y1) + Y(dmin[1].y2)) / 2 + textY;
          }
          return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY;
        })
        .attr("transform", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          let x = 0,
            y = 0;
          if (dd && dd.length > 0) {
            x = X(dd[0].x + textX);
            y = (Y(dd[0].y1) + Y(dd[0].y2)) / 2 + textY;
          } else {
            const dmin = _.filter(d, (t) => t.x == _min);
            if (dmin.length > 1) {
              y = (Y(dmin[1].y1) + Y(dmin[1].y2)) / 2 + textY;
            } else {
              y = (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY;
            }
            x = X(_min + textX);
          }
          const str =
            "translate(" +
            _this.left +
            "," +
            h +
            ")rotate(-90," +
            x +
            "," +
            y +
            ")";
          return str;
        });
    },
    drawGridValue() {
      d3.selectAll(".axis--x .tick")
        .append("line")
        .attr("class", "bg-line")
        .attr("stroke-width", 2)
        .attr("stroke", "rgb(0,0,0)")
        .attr("stroke-opacity", 0.1)
        .attr("shape-rendering", "crispEdges")
        .attr("y2", this.yLength());
    },
    drawGridLabel() {
      d3.selectAll(".axis--y .tick")
        .append("line")
        .attr("class", "bg-line")
        .attr("stroke-width", 2)
        .attr("stroke", "rgb(0,0,0)")
        .attr("stroke-opacity", 0.1)
        .attr("shape-rendering", "crispEdges")
        .attr("x2", -this.xLength());
    },
    drawLabelPoint(svg, X, Y) {
      const dd = _.find(this.groupTabs, (t) => t.style == "point");
      if (dd && dd.show) {
        const colors = this.creatColorScale(dd);
        const r = dd.point_r;
        const one = this.xLength() / 10;
        svg
          .append("g")
          .selectAll("circle")
          .data(this.selectTable)
          .enter()
          .append("circle")
          .attr("r", r)
          .attr("transform", (d, k) => {
            return `translate(${X(0) + one / 2},${
              Y(d.key) + this.space / 2 + this.top
            })`;
          })
          .attr("fill", function (d) {
            return colors(d.key);
          });
      } else {
        d3.selectAll(".axis--y .tick")
          .append("circle")
          .attr("r", this.pointSize)
          .attr("fill", this.pointColor);
      }
    },

    xLength() {
      return this.allWidth - this.rightWidth - 10;
    },
    yLength() {
      return this.height - this.top - 10;
    },
  },
  components: { pageBox },
  computed: {
    column() {
      return _.filter(this.column_All, (t) => !t.hide);
    },
    space: {
      get() {
        return this.ops.space;
      },
      set(v) {
        this.ops.space = v;
      },
    },
    width: {
      get() {
        return this.ops.width;
      },
      set(v) {
        this.ops.width = v;
      },
    },
    valueTitle: {
      get() {
        return this.ops.valueTitle;
      },
      set(v) {
        this.ops.valueTitle = v;
      },
    },
    maxValue: {
      get() {
        return this.ops.maxValue;
      },
      set(v) {
        this.ops.maxValue = v;
      },
    },
    showLabel: {
      get() {
        return this.ops.showLabel;
      },
      set(v) {
        this.ops.showLabel = v;
      },
    },
    labelWidth: {
      get() {
        return this.ops.labelWidth;
      },
      set(v) {
        this.ops.labelWidth = v;
      },
    },
    showMaxLine: {
      get() {
        return this.ops.showMaxLine;
      },
      set(v) {
        this.ops.showMaxLine = v;
      },
    },
    pointSize: {
      get() {
        return this.ops.pointSize;
      },
      set(v) {
        this.ops.pointSize = v;
      },
    },
    lineWeight: {
      get() {
        return this.ops.lineWeight;
      },
      set(v) {
        this.ops.lineWeight = v;
      },
    },
    showGrid_Value: {
      get() {
        return this.ops.showGrid_Value;
      },
      set(v) {
        this.ops.showGrid_Value = v;
      },
    },
    showGrid_Label: {
      get() {
        return this.ops.showGrid_Label;
      },
      set(v) {
        this.ops.showGrid_Label = v;
      },
    },
    valueTitleLeft: {
      get() {
        return this.ops.valueTitleLeft;
      },
      set(v) {
        this.ops.valueTitleLeft = v;
      },
    },
    maxLineColor: {
      get() {
        return this.ops.maxLineColor;
      },
      set(v) {
        this.ops.maxLineColor = v;
      },
    },
    JLData() {
      const data = this.nwk;
      return this.dealNwkData(data);
    },
    showData() {
      const data = this.JLData;
      return this.dealRealData(data);
    },
    selectTable() {
      const data = this.lableAxis;
      return this.dealTableData(data);
    },
  },
  data() {
    return {
      // selectTable: [],
      loading: false,
      color: "#ff4500",
      clickColor: {},
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94)",
        "hsl(181, 100%, 37%)",
        "hsl(209, 100%, 56%)",
      ],
      show_set_column: false,
      column_All: [],
      activeName: "1",
      //激活的分组标签
      activeGroup: "",
      //分组列表
      groupTabs: [],
      //分组数量
      groupIndex: 0,

      height: 0, //内置
      lableAxis: [],
      // showData: [],
      // JLData: [],
      rightWidth: 0,
      allWidth: 0,
      _columns: [],
      top: 50,
      left: 20,
      lineColor: "black",
      pointColor: "black",

      ops: {
        showTabel: false,
        width: 400,
        space: 30,
        valueTitle: "位点差异个数",
        valueTitleLeft: 80,
        maxValue: 10,
        showLabel: true,
        showMaxLine: true,
        labelWidth: 130,
        pointSize: 3,
        lineWeight: 1,
        showGrid_Value: false,
        showGrid_Label: false,
        maxLineColor: "red",
      },
    };
  },
  name: "jltree-new",
};
</script>
<style>
.lx-legend-jtree-new {
  position: absolute;
  right: 0px;
  top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
  height: 80vh;
  padding-left: 10px;
}
.lx-select-color {
  position: fixed;
  top: 200px;
  right: 500px;
}
</style>
