<template>
  <table>
    <tr>
      <th>显示方式：</th>
      <td>
        <el-radio-group v-model="ops.isCircle" size="small">
          <el-radio-button :label="true">圈图</el-radio-button>
          <el-radio-button :label="false">直角图</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr v-if="isJL">
      <th>标题：</th>
      <td>
        <el-input
          v-model="ops.valueTitle"
          size="small"
          placeholder="请输入内容"
        ></el-input>
      </td>
    </tr>
    <tr v-if="isJL">
      <th>标题位置：</th>
      <td>
        <el-input-number
          v-model="ops.valueTitleLeft"
          controls-position="right"
          :min="0"
          :max="ops.width"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr v-if="isJL">
      <th>最大值：</th>
      <td>
        <el-input-number
          v-model="ops.maxValue"
          controls-position="right"
          :min="10"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr v-if="isJL">
      <th>最大值标识线：</th>
      <td>
        <el-radio-group v-model="ops.showMaxLine" size="small">
          <el-radio-button :label="true">显示</el-radio-button>
          <el-radio-button :label="false">隐藏</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr v-if="ops.showMaxLine && isJL">
      <th>标识线颜色：</th>
      <td>
        <el-color-picker
          size="small"
          v-model="ops.maxLineColor"
        ></el-color-picker>
      </td>
    </tr>
    <tr v-if="!isO">
      <th>宽度：</th>
      <td>
        <el-input-number
          v-model="ops.width"
          controls-position="right"
          :min="60"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr v-else>
      <th>半径：</th>
      <td>
        <el-input-number
          v-model="ops.r"
          controls-position="right"
          :min="60"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr>
      <th>显示数值：</th>
      <td>
        <el-radio-group v-model="ops.showLength" size="small">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr>
      <th>标题显示数值：</th>
      <td>
        <el-radio-group v-model="ops.showNumInLabel" size="small">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr>
      <th>是否等长：</th>
      <td>
        <el-radio-group v-model="ops.isSameLength" size="small">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr v-if="!isO">
      <th>垂直间距：</th>
      <td>
        <el-input-number
          v-model="ops.space"
          controls-position="right"
          :min="0"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr v-else>
      <th>角度：</th>
      <td>
        <el-input-number
          v-model="ops.angle"
          controls-position="right"
          :min="-360"
          :max="360"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr>
      <th>延长线长度：</th>
      <td>
        <el-input-number
          v-model="ops.expendLineWidth"
          controls-position="right"
          :min="0"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr>
      <th>节点大小：</th>
      <td>
        <el-input-number
          v-model="ops.pointSize"
          controls-position="right"
          :min="3"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr>
      <th>线条粗细：</th>
      <td>
        <el-input-number
          v-model="ops.lineWeight"
          controls-position="right"
          :min="1"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr>
      <th>名称：</th>
      <td>
        <el-radio-group v-model="ops.showLabel" size="small">
          <el-radio-button :label="true">显示</el-radio-button>
          <el-radio-button :label="false">隐藏</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr>
      <th>对齐方式：</th>
      <td>
        <el-radio-group v-model="ops.isLeft" size="small">
          <el-radio-button :label="true">左对齐</el-radio-button>
          <el-radio-button :label="false">右对齐</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr>
      <th>名称等宽：</th>
      <td>
        <el-radio-group v-model="ops.isSampleLabeWidth" size="small">
          <el-radio-button :label="true">是</el-radio-button>
          <el-radio-button :label="false">否</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr>
      <th>名称宽度：</th>
      <td>
        <el-input-number
          v-model="ops.labelWidth"
          controls-position="right"
          :min="0"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr>
      <th>字体大小：</th>
      <td>
        <el-input-number
          v-model="ops.fontSize"
          controls-position="right"
          :min="8"
          size="small"
        ></el-input-number>
      </td>
    </tr>
    <tr v-if="isJL">
      <th>数值辅助线：</th>
      <td>
        <el-radio-group v-model="ops.showGrid_Value" size="small">
          <el-radio-button :label="true">显示</el-radio-button>
          <el-radio-button :label="false">隐藏</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
    <tr v-if="isJL">
      <th>名称辅助线：</th>
      <td>
        <el-radio-group v-model="ops.showGrid_Label" size="small">
          <el-radio-button :label="true">显示</el-radio-button>
          <el-radio-button :label="false">隐藏</el-radio-button>
        </el-radio-group>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      ops: {
        space: 32,
        isCircle: false,
        expendLineWidth: 0,
        isLeft: true,
        isSampleLabeWidth: false,
        isSameLength: false,
        showLength: true,
        labelWidth: 100,
        width: 400,
        r: 400,
        angle: 360,
        showTabel: false,
        space: 30,
        fontSize: 9,

        valueTitle: "位点差异个数",
        valueTitleLeft: 0,
        maxValue: 5000,
        showLabel: true,
        showMaxLine: true,
        pointSize: 3,
        lineWeight: 1,
        showGrid_Value: false,
        showGrid_Label: false,
        showNumInLabel:false,
        maxLineColor: "red",
      },
    };
  },
  watch: {
    ops: {
      immediate: true,
      deep: true,
      handler(v, ov) {
        this.$emit("change", v);
      },
    },
  },
  computed: {
    isJL() {
      return this.type === "jl";
    },
    isO() {
      return this.ops.isCircle;
    },
  },
  props: {
    type: {
      type: String,
      default: "jl",
    },
  },
};
</script>

<style></style>
