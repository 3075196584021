<template>
  <div>
    <div
      :id="containerName + '1'"
      class="lx-legend"
      :style="{
        height: boxHeight,
      }"
    ></div>
    <div class="lx-select-color" v-show="$refs.color && $refs.color.showPicker">
      <el-color-picker
        ref="color"
        v-model="color"
        :predefine="predefineColors"
        @change="color_change"
      >
      </el-color-picker>
    </div>
  </div>
</template>

<script>
import basemixin from "../mixins";
export default {
  mixins: [basemixin],
  methods: {
    init() {
      const _this = this;
      let main = d3.select("#" + this.containerName + "1");
      d3.selectAll("#lx-svg-legend-box").remove();
      d3.selectAll("#lx-svg-legend").remove();
      const svg_box = main
        .append("svg")
        .attr("id", "lx-svg-legend-box")
        .attr("width", 200);
      const svg = svg_box
        .append("g")
        .attr("id", "lx-svg-legend__" + this.containerName)
        .attr("width", 200);
      let height = 0;
      const legends = _.filter(this.groupTabs, (t) => t.show);
      if (legends.length < 1) {
        return;
      }

      for (let i in legends) {
        const { type, style, group, colors, label, column, prop } = legends[i];
        const d = legends[i];
        const legendList = Object.keys(group);
        const color = this.creatColorScale(d);
        const legend = svg.append("g").attr("class", "lx-svg-legend");
        const g = legend
          .selectAll("g")
          .data(color.domain())
          .enter()
          .append("g")
          .attr("transform", (d, i) => {
            return `translate(9,${(i + 1) * 20 + height})`;
          });

        const clickfun = (d) => {
          const _color = color(d);
          _this.clickColor = {
            color: _color,
            prop,
          };
          _this.$refs.color.showPicker = true;
        };
        if (style == "rect") {
          const rect = g
            .append("rect")
            .attr("width", 18)
            .attr("height", 18)
            .attr("fill", (d) => {
              return color(d);
            });
          rect.on("click", null);
          rect.on("click", clickfun);
        }
        if (style == "circle") {
          const circle = g
            .append("circle")
            .attr("r", 9)
            .attr("cx", 9)
            .attr("cy", 9)
            .attr("fill", (d) => {
              return color(d);
            });
          circle.on("click", null);
          circle.on("click", clickfun);
        }

        if (style == "point") {
          const point = g
            .append("circle")
            .attr("r", 5)
            .attr("cx", 9)
            .attr("cy", 9)
            .attr("fill", (d) => {
              return color(d);
            });
          point.on("click", null);
          point.on("click", clickfun);
        }

        g.append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text((d) => d);
        legend
          .append("g")
          .attr("transform", `translate(0,${height})`)
          .append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text(label);
        height = height + legendList.length * 20 + 30;
      }
      svg_box.attr("height", height + 30);
      svg.attr("height", height + 30);
    },
    color_change(v) {
      const { color, prop } = this.clickColor;
      const i = _.findIndex(this.groupTabs, (t) => t.prop == prop);
      if (i >= 0) {
        let cc = this.groupTabs[i].colors;
        const ci = cc.indexOf(color);
        cc[ci] = v;
        this.groupTabs[i].colors = [].concat(cc);
      }
    },
  },
  computed: {
    boxHeight() {
      const legends = _.filter(this.groupTabs, (t) => t.show);
      if (legends.length < 1) {
        return "0px";
      }
      return "80vh";
    },
  },
  mounted() {},
  data() {
    return {
      color: "#ff4500",
      clickColor: {},
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94)",
        "hsl(181, 100%, 37%)",
        "hsl(209, 100%, 56%)",
      ],
    };
  },
};
</script>

<style>
.lx-legend {
  position: absolute;
  right: 0px;
  top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
}
.lx-select-color {
  position: fixed;
  top: 200px;
  right: 500px;
}
</style>
