<template>
  <el-row>
    <el-col :span="24" style="text-align: center">
      <table style="display: inline-block">
        <th>图例：</th>
        <td>
          <!-- multiple -->
          <el-select
            multiple
            v-model="addLegendList"
            placeholder="请选择图例"
            @change="controlChange"
          >
            <template v-for="(item, i) in column">
              <el-option :key="i" :label="item.label" :value="item.prop">
              </el-option>
            </template>
          </el-select>
        </td>
        <th>是否圈图</th>
        <td>
          <el-switch v-model="isCircle" @change="controlChange"> </el-switch>
        </td>
        <th>是否等长</th>
        <td>
          <el-switch v-model="isSameLength" @change="controlChange">
          </el-switch>
        </td>
        <th>显示数值</th>
        <td>
          <el-switch v-model="showLength" @change="controlChange"> </el-switch>
        </td>
        <td>
          <el-button type="primary" @click="resetShow" size="small"
            >恢复</el-button
          >
          <el-button type="primary" size="small" @click="savesvg"
            >另存为SVG</el-button
          >
          <el-button type="primary" size="small" @click="savepdf"
            >另存为PDF</el-button
          >
        </td>
      </table>
    </el-col>
    <el-col :span="24">
      <div :id="containerName" :style="{ width: '100%', height: h }"></div>
    </el-col>
    <div
      :id="containerName + '1'"
      class="lx-legend"
      :style="{
        height: legendHeight,
      }"
    ></div>
  </el-row>
</template>
<script>
import * as d3 from "d3";
import _ from "lodash";
import * as svgPanZoom from "svg-pan-zoom";
import { saveTextAsFile, saveAsPdf } from "@/assets/js/help";
import {
  buildNewickNodes,
  buildNewick,
  retrieveIdList,
  filterLeaf,
  loadMetaFile,
} from "@/assets/js/datasource.js";

export default {
  name: "phylogram",
  data() {
    return {
      legendValue: "key",
      addLegendList: ["key"],
      showLength: false,
      isSameLength: false,
      isCircle: true,
      width: 1000,
      height: 1000,
      margin: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      labelWidth: 200,
    };
  },
  props: {
    h: {
      type: String,
      default: "900px",
    },
    containerName: {
      type: String,
      default: "lxlx",
    },
    nwk: {
      type: String,
      default: "",
    },
    tableData: {
      type: Array,
      default() {
        const { newick, newickNodes } = buildNewick(this.nwk);
        const idlist = retrieveIdList(newickNodes);
        return _.map(idlist, (t, i) => ({
          key: t,
          st: i < 50 ? 30 : i < 100 ? 20 : 10,
          area: i < 50 ? "中国" : i < 100 ? "美国" : "英国",
        }));
      },
    },
    column: {
      type: Array,
      default() {
        return [
          {
            label: "Key",
            prop: "key",
          },
          {
            label: "ST型",
            prop: "st",
          },
          {
            label: "区域",
            prop: "area",
          },
        ];
      },
    },
  },
  computed: {
    legendHeight() {
      return parseInt(this.h) * 0.8 + this.h.replace(/[0-9]/g, "");
    },
    outerRadius() {
      return this.width / 2;
    },
    innerRadius() {
      return this.outerRadius - this.labelWidth;
      return this.outerRadius - 170;
    },
    firstData() {
      const { newick, newickNodes } = buildNewick(this.nwk);
      const idlist = retrieveIdList(newickNodes);
      const maxId = _.maxBy(idlist, (t) => t.length);
      return { newick, newickNodes, idlist, maxId };
    },
    okData() {
      const { newick, newickNodes, idlist, maxId } = this.firstData;
      const _this = this;
      const list =
        this.addLegendList.length > 0 ? this.addLegendList : [this.legendValue];
      const legends = _.map(list, (l) => {
        const legendGroup = _.groupBy(_this.tableData, (t) => t[l]);
        const legendList = _.map(legendGroup, (t, i) => i);
        const col = _.find(_this.column, (t) => t.prop == l);
        const color = _this.creatColor(legendList);
        return { legendGroup, legendList, col, color, maxId };
      });
      const _legendValue =
        this.addLegendList.length > 0
          ? this.addLegendList[0]
          : this.legendValue;
      // console.log(_legendValue, "_legendValue");
      const legendGroup = _.groupBy(this.tableData, (t) => t[_legendValue]);
      const legendList = _.map(legendGroup, (t, i) => i);
      return {
        newick,
        newickNodes,
        idlist,
        legendList,
        legendGroup,
        legends,
        maxId,
      };
    },
    colors() {
      const { idlist } = this.firstData;
      let color = [];
      for (let i in idlist) {
        color.push("#" + Math.floor(Math.random() * 0xffffff).toString(16));
      }
      return color;
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.init();
    });
  },
  methods: {
    makeLegendList(value) {
      const legendGroup = _.groupBy(this.tableData, (t) => t[value]);
      const legendList = _.map(legendGroup, (t, i) => i);
      return { legendList, legendGroup };
    },
    savesvg() {
      const _this = this;
      // this.resetShow();
      window.setTimeout(() => {
        const data = _this.getSVG();
        saveTextAsFile(data.svg, "Phylogram.svg");
      }, 200);
    },
    getSVG() {
      const size = window.zoomTiger.getSizes();
      const room = size.realZoom;
      const { newick, newickNodes, idlist, legendList, legendGroup } =
        this.okData;
      let svg = document.getElementById("lx-svg");
      let leg = document.getElementById("lx-svg-legend");
      const leghtml = leg.outerHTML;
      var ori_pos = document.getElementById(this.containerName + "1");
      leg.setAttribute(
        "transform",
        "translate(" + ori_pos.offsetLeft + "," + ori_pos.offsetTop + ")"
      );
      svg.appendChild(leg);
      const _height = leg.getAttribute("height");
      svg.setAttribute("height", _height < size.height ? size.height : _height);
      const svgData = document.getElementById("lx-svg").outerHTML;
      const w = svg.clientWidth;
      const h = svg.clientHeight;

      svg.setAttribute("height", parseInt(this.h) - 30);
      leg.setAttribute("transform", "translate(0,0)");
      document.getElementById("lx-svg-legend-box").appendChild(leg);
      const svgDataOK = ["<svg ", svgData.substring(5, 9999999)].join("");
      return {
        svg: svgDataOK,
        w,
        h,
      };
    },
    savepdf() {
      const _this = this;
      // this.resetShow();
      window.setTimeout(() => {
        const data = _this.getSVG();
        if (this.$listeners["savepdf"]) {
          this.$emit("savepdf", { svg: the_tree.getSVG(), w, h });
        } else {
          saveAsPdf(data.svg, "Phylogram", data.w, data.h);
        }
      }, 200);
    },
    resetShow() {
      window.zoomTiger.reset();
    },
    bindZoom() {
      window.zoomTiger = svgPanZoom("#lx-svg", {
        zoomEnabled: true,
        controlIconsEnabled: false,
        fit: true,
        center: true,
        minZoom: 0.1,
        maxZoom: 100,
      });
    },
    controlChange() {
      this.init();
    },
    init() {
      this.clear();
      //数据处理
      const { newick, newickNodes, idlist, legendList, legendGroup, legends } =
        this.okData;
      // console.log(legends);
      if (!this.isCircle) {
        this.height = idlist.length * 15;
      } else {
        this.height = parseInt(this.h);
        const _w = idlist.length * 4;
        this.width = _w < 1000 ? 1000 : _w;
        // this.height = idlist.length;
      }
      //创建svg并添加到dom
      const svg = this.creatSVG(this.containerName);
      //创建颜色比例尺
      const color = this.creatColor(legendList);
      //创建图例并添加到dom
      // this.creatLegend(color, legendList, "Key");
      this.creatLegends(legends);

      //创建root分层数据
      const root = this.creatRoot(newick);
      //创建簇
      const cluster = this.creatCluster(root, color);
      const end = this.setColor(root, color);
      const { nodes, root_type_root } = this.creatNodes(cluster, end);
      const maxLength = this.getMaxLength(nodes);
      const x = this.creatX(maxLength);
      if (!this.isCircle) {
        this.visitPreOrder(root_type_root, function (node) {
          node.real_y = x(node.rootDist);
        });
      }
      const roots = cluster.links(nodes);
      // console.log(roots, nodes);
      this.drawLinkExtension(svg, roots);
      this.drawLink(svg, roots);
      this.drawPoint(svg, roots);
      this.drawText(svg, nodes);
      this.drawLegend(svg, nodes, legends);
      this.bindZoom();
    },
    creatX(maxDist) {
      const _this = this;
      const x = d3.scale
        .linear()
        .domain([0, maxDist])
        .range([
          0,
          _this.width -
            _this.margin.left -
            _this.margin.right -
            _this.labelWidth -
            300,
        ]);
      return x;
    },
    getMaxLength(nodes) {
      if (this.isCircle) {
        return 1;
      }
      var rootDists = nodes.map(function (n) {
        return n.rootDist;
      });
      const maxDist = d3.max(rootDists);
      return maxDist;
    },
    clear() {
      d3.selectAll(this.containerName).remove();
      d3.selectAll(`${this.containerName}1`).remove();
      
    },
    creatSVG(id) {
      const _this = this;
      let main = d3.select("#" + id);
      const viewBox = this.isCircle
        ? [-_this.outerRadius, -_this.outerRadius, _this.width, _this.width]
        : [-100, 0, _this.width + 100, _this.height];
      const svg = main
        .append("svg")
        .attr("id", "lx-svg")
        .attr("viewBox", viewBox)
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("width", "100%")
        .attr("height", parseInt(this.h) - 30);
      return svg;
    },
    creatColor(legendList) {
      const range = this.colors;
      //新语法
      // let color = d3.scaleOrdinal().domain(legendList).range(range);
      let color = d3.scale.ordinal().domain(legendList).range(range);
      return color;
    },
    creatLegends(legends) {
      let main = d3.select("#" + this.containerName + "1");
      const svg_box = main
        .append("svg")
        .attr("id", "lx-svg-legend-box")
        .attr("width", 200);
      const svg = svg_box
        .append("g")
        .attr("id", "lx-svg-legend")
        .attr("width", 200);
      let height = 0;
      for (let i in legends) {
        const { legendGroup, legendList, col, color } = legends[i];
        const legend = svg.append("g").attr("class", "lx-svg-legend");
        const g = legend
          .selectAll("g")
          .data(color.domain())
          .enter()
          .append("g")
          .attr("transform", (d, i) => {
            // console.log((i + 1) * 20 + height, col.label);
            return `translate(0,${(i + 1) * 20 + height})`;
          });
        g.append("rect")
          .attr("width", 18)
          .attr("height", 18)
          .attr("fill", (d) => {
            return color(d);
          });
        g.append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text((d) => d);
        legend
          .append("g")
          .attr("transform", `translate(0,${height})`)
          .append("text")
          .attr("x", 24)
          .attr("y", 9)
          .attr("dy", "0.35em")
          .text(col.label);
        height = height + legendList.length * 20 + 30;
      }
      svg_box.attr("height", height + 30);
      svg.attr("height", height + 30);
    },
    creatLegend(color, legendList, name) {
      let main = d3.select("#" + this.containerName + "1");
      const svg = main
        .append("svg")
        .attr("id", "lx-svg-legend-box")
        .attr("width", 200)
        .attr("height", legendList.length * 20 + 30);

      const legend = svg.append("g").attr("id", "lx-svg-legend");

      const g = legend
        .selectAll("g")
        .data(color.domain())
        .enter()
        .append("g")
        .attr("transform", (d, i) => `translate(0,${(i + 1) * 20})`);
      g.append("rect")
        .attr("width", 18)
        .attr("height", 18)
        .attr("fill", (d) => {
          // console.log(d);
          return color(d);
        });
      g.append("text")
        .attr("x", 24)
        .attr("y", 9)
        .attr("dy", "0.35em")
        .text((d) => d);
      legend
        .append("g")
        .attr("transform", "translate(0,0)")
        .append("text")
        .attr("x", 24)
        .attr("y", 9)
        .attr("dy", "0.35em")
        .text(name);
    },
    creatRoot(newick) {
      return newick;
    },
    visitPreOrder(node, callback) {
      callback(node);
      if (node.children) {
        for (var i = node.children.length - 1; i >= 0; i--) {
          this.visitPreOrder(node.children[i], callback);
        }
      }
    },
    creatNodes(cluster, root) {
      let nodes = cluster(root)
        .map((n, i) => {
          let type = "leaf";
          if (n.children) {
            if (n.depth === 0) {
              type = "root";
            } else {
              type = "inner";
            }
          }
          n.type = type;
          n.id = i;
          return n;
        })
        .sort(function (a, b) {
          if (_.isEmpty(a.children)) {
            return 1;
          }
          if (_.isEmpty(b.children)) {
            return -1;
          }
          if (a.children.length > b.children.length) {
            return -1;
          }
          return 1;
        });
      const root1 = nodes.find((n) => {
        return n.type === "root";
      });
      this.visitPreOrder(root1, function (node) {
        let branchLength = 0;
        if (node.data && node.data.length) {
          branchLength = node.data.length > 0 ? node.data.length : 0;
        }
        node.rootDist = 0;
        if (node.parent) {
          if (node.parent.rootDist) {
            node.rootDist = node.parent.rootDist;
          }
        }
        node.rootDist += branchLength;
      });
      return { nodes, root_type_root: root1 };
    },
    creatCluster(root, color) {
      if (this.isCircle) {
        let cluster = d3.layout
          .cluster()
          .size([360, this.innerRadius])
          .separation((a, b) => 1);
        //将分层数据传递到簇
        const nodes = cluster(root);
        // console.log(nodes, root);
        this.setRadius(
          root,
          (root.data.length = 0),
          this.innerRadius / this.maxLength(root)
        );

        return cluster;
      }
      let cluster1 = d3.layout
        .cluster()
        .size([
          this.height - this.margin.top,
          this.width - this.margin.left - this.margin.right - this.labelWidth,
        ])
        .separation(function (a, b) {
          return 50;
        });
      this.setColor(root, color);
      return cluster1;
    },
    setColor(root, color) {
      const _this = this;
      const d = root;
      // Set the color of each node by recursively inheriting.
      const n = d.name;
      const dd = _.find(this.tableData, (t) => t.key == d.name);
      const _legendValue =
        this.addLegendList.length > 0
          ? this.addLegendList[0]
          : this.legendValue;
      var name = dd ? dd[_legendValue] : "";
      d.color =
        color.domain().indexOf(name + "") >= 0
          ? color(name)
          : d.parent
          ? d.parent.color
          : null;
      if (d.children) d.children.forEach((t) => _this.setColor(t, color));
      return d;
    },
    drawLinkExtension(svg, root) {
      const linkExtension = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .attr("stroke-opacity", 0.25)
        .selectAll("path")
        .data(root.filter((d) => !d.target.children))
        .enter()
        .append("path")
        .each(function (d) {
          d.target.linkExtensionNode = this;
        })
        .attr("stroke-dasharray", "5,5")
        .attr(
          "d",
          this.isSameLength
            ? this.linkExtensionConstant
            : this.linkExtensionVariable
        );
      return linkExtension;
    },
    drawLink(svg, root) {
      const link = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .selectAll("path")
        .data(root)
        .enter()
        .append("path")
        .each(function (d) {
          d.target.linkNode = this;
        })
        .attr("d", this.isSameLength ? this.linkConstant : this.linkVariable)
        .attr("stroke", (d) => d.target.color);
      return link;
    },
    drawPoint(svg, root) {
      const _this = this;
      if (this.isCircle) {
        const x = (endAngle, endRadius) => {
          const d = ((endAngle - 90) / 180) * Math.PI;
          const c1 = Math.cos(d);
          return endRadius * c1;
        };
        const y = (endAngle, endRadius) => {
          const d = ((endAngle - 90) / 180) * Math.PI;
          const s1 = Math.sin(d);
          return endRadius * s1;
        };
        svg
          .append("g")
          .selectAll("circle")
          .data(root.filter((d) => !d.target.children))
          .enter()
          .append("circle")
          .attr("r", "3")
          .attr("fill", (d) => d.target.color)
          .attr("cx", (d) => {
            return x(
              d.target.x,
              _this.isSameLength ? d.target.y : d.target.radius
            );
          })
          .attr("cy", (d) => {
            return y(
              d.target.x,
              _this.isSameLength ? d.target.y : d.target.radius
            );
          });
        return;
      }
      svg
        .append("g")
        .selectAll("circle")
        .data(root.filter((d) => !d.target.children))
        .enter()
        .append("circle")
        .attr("r", "3")
        .attr("fill", (d) => d.target.color)
        .attr("cx", (d) => {
          return _this.isSameLength ? d.target.y : d.target.real_y;
        })
        .attr("cy", (d) => {
          return d.target.x;
        });
    },
    drawText(svg, root) {
      const d = root.filter((d) => d.type == "leaf");
      const _this = this;
      const text = (d) => {
        return _this.showLength ? `${d.name}(${d.length})` : `${d.name}`;
      };
      if (this.isCircle) {
        svg
          .append("g")
          .selectAll("text")
          .data(d)
          .enter()
          .append("text")
          .attr("dy", ".11em")
          .attr(
            "transform",
            (d) =>
              `rotate(${d.x - 90}) translate(${this.innerRadius + 4},${
                d.x < 180 ? 3 : -3
              })${d.x < 180 ? " " : " rotate(180)"}`
          )
          .attr("text-anchor", (d) => (d.x < 180 ? "start" : "end"))
          .text(text)
          .on("mouseover", this.mouseovered(true))
          .on("mouseout", this.mouseovered(false));
        return;
      }
      svg
        .append("g")
        .selectAll("text")
        .data(d)
        .enter()
        .append("text")
        .attr("dy", ".11em")
        .attr("transform", (d) => `translate(${d.y + 10},${d.x})`)
        .text(text)
        .on("mouseover", this.mouseovered(true))
        .on("mouseout", this.mouseovered(false));
      return;
    },
    drawLegend(svg, root, legends) {
      const dd = root.filter((d) => d.type == "leaf");
      const _this = this;
      if (this.isCircle) {
        for (let i in legends) {
          const { legendGroup, legendList, col, color, maxId } = legends[i];
          const legend = svg.append("g");
          legend
            .selectAll("rect")
            .data(dd)
            .enter()
            .append("rect")
            .attr("width", 50)
            .attr("height", 10)
            .attr("transform", (d, k) => {
              if (k == 0) {
                legend
                  .append("text")
                  .attr("dy", ".11em")
                  .attr(
                    "transform",
                    `translate(0,-${
                      this.innerRadius + maxId.length * 7 + 30 + i * 80 + 75
                    })`
                  )
                  .text(col.label);
              }

              return `rotate(${d.x - 90}) translate(${
                this.innerRadius + maxId.length * 7 + 30 + i * 80 + 10
              },${d.x < 180 ? 3 : -3})`;
            })
            .attr("fill", (d) => {
              const td = _.find(this.tableData, (t) => t.key == d.name);
              // console.log(d, "a");
              return color(td[col.prop]);
            });
          // if (i < 1) {
          //   break;
          // }
        }
        return;
      }
      for (let i in legends) {
        const { legendGroup, legendList, col, color, maxId } = legends[i];
        const legend = svg.append("g");
        legend
          .selectAll("rect")
          .data(dd)
          .enter()
          .append("rect")
          .attr("width", 50)
          .attr("height", 10)
          .attr("transform", (d, k) => {
            if (k == 0) {
              legend
                .append("text")
                .attr("dy", ".11em")
                .attr(
                  "transform",
                  `translate(${d.y + maxId.length * 7 + 50 + i * 80 + 15},-10)`
                )
                .text(col.label);
            }
            return `translate(${d.y + maxId.length * 7 + 50 + i * 80 + 10},${
              d.x - 8
            })`;
          })
          .attr("fill", (d) => {
            const td = _.find(this.tableData, (t) => t.key == d.name);
            return color(td[col.prop]);
          });
      }
    },
    mouseovered(active) {
      return function (d) {
        d3.select(this).classed("label--active", active);
        d3.select(d.linkExtensionNode).classed(
          "link-extension--active",
          active
        );
        do d3.select(d.linkNode).classed("link--active", active);
        while ((d = d.parent));
      };
    },
    update(checked, link, linkExtension) {
      const t = d3.transition().duration(750);
      linkExtension
        .transition(t)
        .attr(
          "d",
          checked ? this.linkExtensionVariable : this.linkExtensionConstant
        );
      link
        .transition(t)
        .attr("d", checked ? this.linkVariable : this.linkConstant);
    },

    maxLength(d) {
      // Compute the maximum cumulative length of any node in the tree.
      return (
        d.data.length + (d.children ? d3.max(d.children, this.maxLength) : 0)
      );
    },
    setRadius(d, y0, k) {
      // Set the radius of each node by recursively summing and scaling the distance from the root.
      d.radius = (y0 += d.data.length) * k;
      if (d.children) d.children.forEach((d) => this.setRadius(d, y0, k));
    },
    linkVariable(d) {
      if (this.isCircle) {
        return this.linkStep(
          d.source.x,
          d.source.radius,
          d.target.x,
          d.target.radius
        );
      }
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.real_y },
        { x: d.target.x, y: d.target.real_y }
      );
    },
    linkConstant(d) {
      if (this.isCircle) {
        return this.linkStep(d.source.x, d.source.y, d.target.x, d.target.y);
      }
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.y },
        { x: d.target.x, y: d.target.y }
      );
    },
    linkExtensionVariable(d) {
      if (this.isCircle) {
        return this.linkStep(
          d.target.x,
          d.target.radius,
          d.target.x,
          this.innerRadius
        );
      }
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.real_y },
        { x: d.target.x, y: d.target.y }
      );
    },
    linkExtensionConstant(d) {
      if (this.isCircle) {
        return this.linkStep(
          d.target.x,
          d.target.y,
          d.target.x,
          this.innerRadius
        );
      }
      return this.linkDiagonal(
        { x: d.source.x, y: d.source.y },
        { x: d.target.x, y: d.target.y }
      );
    },
    linkDiagonal(startpoint, endpoint) {
      let projection = function (d) {
        return [d.y, d.x];
      };
      let pathData = [
        { x: startpoint.x, y: startpoint.y },
        { x: endpoint.x, y: startpoint.y },
        { x: endpoint.x, y: endpoint.y },
      ];
      pathData = pathData.map(projection);
      return "M" + pathData[0] + " " + pathData[1] + " " + pathData[2];
    },
    linkStep(startAngle, startRadius, endAngle, endRadius) {
      const c0 = Math.cos((startAngle = ((startAngle - 90) / 180) * Math.PI));
      const s0 = Math.sin(startAngle);
      const c1 = Math.cos((endAngle = ((endAngle - 90) / 180) * Math.PI));
      const s1 = Math.sin(endAngle);
      return (
        "M" +
        startRadius * c0 +
        "," +
        startRadius * s0 +
        (endAngle === startAngle
          ? ""
          : "A" +
            startRadius +
            "," +
            startRadius +
            " 0 0 " +
            (endAngle > startAngle ? 1 : 0) +
            " " +
            startRadius * c1 +
            "," +
            startRadius * s1) +
        "L" +
        endRadius * c1 +
        "," +
        endRadius * s1
      );
    },
  },
};
</script>

<style lang="less">
.link--active {
  stroke: #000 !important;
  stroke-width: 1.5px;
}

.link-extension--active {
  stroke-opacity: 0.6;
}

.label--active {
  font-weight: bold;
}
.lx-legend {
  position: absolute;
  right: 0px;
  top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
}
</style>
