<template>
  <div :id="containerName" style="width: 100%; height: 100%"></div>
</template>

<script>
import basemixin from "../mixins";
import * as d3 from "d3";
import _ from "lodash";

export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    init() {
      this.clear();
      this.getSize();
      const svg = this.creatSVG();
      const { newick, newickNodes, idlist, maxId } = this.okData;
      const cluster = this.creatCluster(newick);
      const { nodes, root_type_root } = this.creatNodes(cluster, newick);
      const roots = cluster.links(nodes);
      const l = this.getMaxLabelLength(nodes);
      this.maxLableWidth = l * this.textWidth;
      this.drawLinkExtension(svg, roots);
      this.drawLink(svg, roots);
      this.drawPoint(svg, roots);
      this.drawText(svg, nodes);
      this.drawGroup(svg, nodes);
      this.drawTable(svg, nodes);
      this.bindZoom("lx-svg__" + this.containerName);
    },

    creatSVG() {
      const _this = this;
      let main = d3.select("#" + this.containerName);
      const viewBox = [
        -_this.width / 2,
        -_this.width / 2,
        _this.width,
        _this.width,
      ];
      const svg = main
        .append("svg")
        .attr("id", "lx-svg__" + this.containerName)
        .attr("viewBox", viewBox)
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("width", "100%")
        .attr("height", parseInt(this.height) - 30);
      return svg;
    },
    setRadius(d, y0, k) {
      // 通过递归求和和缩放到根的距离来设置每个节点的半径。
      d.radius = (y0 += d.data.length) * k;
      if (d.children) d.children.forEach((d) => this.setRadius(d, y0, k));
    },
    maxLength(d) {
      return (
        d.data.length + (d.children ? d3.max(d.children, this.maxLength) : 0)
      );
    },
    getMaxLabelLength(root) {
      const d = root.filter((d) => d.type == "leaf");
      const maxLengthLabel = _.maxBy(d, (t) => t.name.length);
      const length = maxLengthLabel.name.length;
      return length;
    },
    creatCluster(root) {
      let cluster = d3.layout
        .cluster()
        .size([this.angle, this.innerRadius])
        .separation((a, b) => 1);
      //将分层数据传递到簇
      const nodes = cluster(root);
      this.setRadius(
        root,
        (root.data.length = 0),
        this.innerRadius / this.maxLength(root)
      );

      return cluster;
    },
    visitPreOrder(node, callback) {
      callback(node);
      if (node.children) {
        for (var i = node.children.length - 1; i >= 0; i--) {
          this.visitPreOrder(node.children[i], callback);
        }
      }
    },
    creatNodes(cluster, root) {
      let nodes = cluster(root)
        .map((n, i) => {
          let type = "leaf";
          if (n.children) {
            if (n.depth === 0) {
              type = "root";
            } else {
              type = "inner";
            }
          }
          n.type = type;
          n.id = i;
          return n;
        })
        .sort(function (a, b) {
          if (_.isEmpty(a.children)) {
            return 1;
          }
          if (_.isEmpty(b.children)) {
            return -1;
          }
          if (a.children.length > b.children.length) {
            return -1;
          }
          return 1;
        });
      const root1 = nodes.find((n) => {
        return n.type === "root";
      });
      this.visitPreOrder(root1, function (node) {
        let branchLength = 0;
        if (node.data && node.data.length) {
          branchLength = node.data.length > 0 ? node.data.length : 0;
        }
        node.rootDist = 0;
        if (node.parent) {
          if (node.parent.rootDist) {
            node.rootDist = node.parent.rootDist;
          }
        }
        node.rootDist += branchLength;
      });
      return { nodes, root_type_root: root1 };
    },
    drawLinkExtension(svg, root) {
      const linkExtension = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .attr("stroke-opacity", 0.25)
        .selectAll("path")
        .data(root.filter((d) => !d.target.children))
        .enter()
        .append("path")
        .each(function (d) {
          d.target.linkExtensionNode = this;
        })
        .attr("stroke-dasharray", "5,5")
        .attr(
          "d",
          this.isSameLength
            ? this.linkExtensionConstant
            : this.linkExtensionVariable
        );
      return linkExtension;
    },
    drawLink(svg, root) {
      const link = svg
        .append("g")
        .attr("fill", "none")
        .attr("stroke", "#000")
        .selectAll("path")
        .data(root)
        .enter()
        .append("path")
        .each(function (d) {
          d.target.linkNode = this;
        })
        .attr("d", this.isSameLength ? this.linkConstant : this.linkVariable);
      // .attr("stroke", (d) => d.target.color);
      return link;
    },
    drawPoint(svg, root) {
      const _this = this;
      let point = _.find(this.groupTabs, (t) => t.show && t.style == "point");
      let color = null;
      if (point) {
        color = this.creatColorScale(point);
      }
      const x = (endAngle, endRadius) => {
        const d = ((endAngle - 90) / 180) * Math.PI;
        const c1 = Math.cos(d);
        return endRadius * c1;
      };
      const y = (endAngle, endRadius) => {
        const d = ((endAngle - 90) / 180) * Math.PI;
        const s1 = Math.sin(d);
        return endRadius * s1;
      };
      svg
        .append("g")
        .selectAll("circle")
        .data(root.filter((d) => !d.target.children))
        .enter()
        .append("circle")
        .attr("r", point ? point.point_r : "3")
        .attr("fill", (d) => {
          const td = _.find(this.tableData, (t) => t.key == d.target.name);
          if (color && td) {
            return color(td[point.prop]);
          }
          return "#000";
        })
        .attr("cx", (d) => {
          return x(
            d.target.x,
            _this.isSameLength ? d.target.y : d.target.radius
          );
        })
        .attr("cy", (d) => {
          return y(
            d.target.x,
            _this.isSameLength ? d.target.y : d.target.radius
          );
        });
    },
    drawText(svg, root) {
      const d = root.filter((d) => d.type == "leaf");
      const _this = this;
      const text = (d) => {
        return _this.options.showLength ? `${d.name}(${d.length})` : `${d.name}`;
      };
      const textSvg = svg
        .append("g")
        .selectAll("text")
        .data(d)
        .enter()
        .append("text");
      if (this.isSampleLabeWidth) {
        textSvg
          .attr("textLength", this.labelWidth)
          .attr("lengthAdjust", "spacingAndGlyphs");
      }
      textSvg
        .attr("dy", ".11em")
        .attr("font-size", this.options.fontSize)
        .attr(
          "transform",
          (d) =>
            `rotate(${d.x - 90}) translate(${
              _this.computeExtendEndRadius() + 4
            },${d.x < 180 ? 3 : -3})${d.x < 180 ? " " : " rotate(180)"}`
        )
        .attr("text-anchor", (d) => {
          if (_this.isLeft) {
            return d.x < 180 ? "start" : "end";
          } else {
            return d.x < 180 ? "end" : "start";
          }
        })
        .text(text)
        .on("mouseover", this.mouseovered(true))
        .on("mouseout", this.mouseovered(false));
    },
    computedGroupLeft(item) {
      if (item.style == "rect") {
        let left = this.computeExtendEndRadius() + item.w + item.left;
        if (this.isSampleLabeWidth) {
          left = left + 30;
          if (!this.isLeft) {
            //右对齐，文字从外向内，需要减掉文字固定长度
            left = left - this.labelWidth;
          }
        } else {
          left = left + 20;
          if (this.isLeft) {
            //左对齐，需加上最大文字长度
            left = left + this.maxLableWidth;
          } else {
            //右对齐，减掉形状固定长度
            left = left - item.w - 10;
          }
        }
        return left;
      } else if (item.style == "circle") {
        let left = this.computeExtendEndRadius() + item.r * 2 + item.left;
        if (this.isSampleLabeWidth) {
          left = left + 30;
          if (!this.isLeft) {
            //右对齐，文字从外向内，需要减掉文字固定长度
            left = left - this.labelWidth;
          }
        } else {
          left = left + 20;
          if (this.isLeft) {
            //左对齐，需加上最大文字长度
            left = left + this.maxLableWidth;
          } else {
            //右对齐，减掉形状固定长度
            left = left - item.r - 10;
          }
        }
        return left;
      }
    },
    creatRectGroup(svg, item, data) {
      if (item.style !== "rect") {
        return;
      }
      const _this = this;
      const color = this.creatColorScale(item);
      const left = this.computedGroupLeft(item);
      if (item.type == "more") {
        const { column, data: tableData } = item;
        column.forEach((c, i) => {
          const legend = svg.append("g");
          legend
            .selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("width", item.w)
            .attr("height", item.h)
            .attr("transform", (d, k) => {
              if (
                (k == 0 && _this.angle < 0) ||
                (k == data.length - 1 && _this.angle >= 0)
              ) {
                legend
                  .append("text")
                  .attr("dy", ".11em")
                  .attr(
                    "transform",
                    `rotate(${d.x - 90}) translate(${
                      left +
                      item.w * i +
                      item.w / 2 -
                      (item.label.length * 6) / 2
                    },${-item.h / 2 - 10}) `
                  )
                  .attr("font-weight", "bold")
                  .text(c);
              }

              return `rotate(${d.x - 90}) translate(${
                left + (item.w + item.space) * i
              },${-item.h / 2})`;
            })
            .attr("fill", (d) => {
              const td = _.find(tableData, (t) => t.key == d.name);
              if (td) return color(td[c]);
            });
        });
        return;
      }
      const legend = svg.append("g");
      legend
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("width", item.w)
        .attr("height", item.h)
        .attr("transform", (d, k) => {
          if (
            (k == 0 && _this.angle < 0) ||
            (k == data.length - 1 && _this.angle >= 0)
          ) {
            legend
              .append("text")
              .attr("dy", ".11em")
              .attr(
                "transform",
                `rotate(${d.x - 90}) translate(${
                  left + item.w / 2 - (item.label.length * 6) / 2
                },${-item.h / 2 - 10})`
              )
              .attr("font-weight", "bold")
              .text(item.label);
          }

          return `rotate(${d.x - 90}) translate(${left},${
            d.x < 180 ? -item.h / 2 : -item.h / 2
          })`;
        })
        .attr("fill", (d) => {
          const td = _.find(this.tableData, (t) => t.key == d.name);
          if (td) return color(td[item.prop]);
        });
    },
    creatCircleGroup(svg, item, data) {
      if (item.style !== "circle") {
        return;
      }
      const color = this.creatColorScale(item);
      const left = this.computedGroupLeft(item);
      if (item.type == "more") {
        const { column, data: tableData } = item;
        column.forEach((c, i) => {
          const legend = svg.append("g");
          legend
            .selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("r", item.r)
            .attr("transform", (d, k) => {
              if (k == 0) {
                legend
                  .append("text")
                  .attr("dy", ".11em")
                  .attr(
                    "transform",
                    `translate(0,-${left + item.r * 2 * i + 10})`
                  )
                  .attr("font-weight", "bold")
                  .text(c);
              }

              return `rotate(${d.x - 90}) translate(${
                left + (item.r * 2 + item.space) * i
              },${d.x < 180 ? 0 : 0})`;
            })
            .attr("fill", (d) => {
              const td = _.find(tableData, (t) => t.key == d.name);
              if (td) {
                return color(td[c]);
              }
            });
        });
        return;
      }
      const legend = svg.append("g");

      legend
        .selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("r", item.r)
        .attr("transform", (d, k) => {
          if (k == 0) {
            legend
              .append("text")
              .attr("dy", ".11em")
              .attr("transform", `translate(0,-${left + item.r + 10})`)
              .attr("font-weight", "bold")
              .text(item.label);
          }

          return `rotate(${d.x - 90}) translate(${left},${d.x < 180 ? 0 : 0})`;
        })
        .attr("fill", (d) => {
          const td = _.find(this.tableData, (t) => t.key == d.name);
          if (td) return color(td[item.prop]);
        });
    },
    drawGroup(svg, root) {
      let legends = _.filter(
        this.groupTabs,
        (t) => t.show && t.style != "point"
      );
      const dd = root.filter((d) => d.type == "leaf");
      const _this = this;
      for (let i in legends) {
        const item = legends[i];
        this.creatRectGroup(svg, item, dd);
        this.creatCircleGroup(svg, item, dd);
      }
    },
    drawLegend(svg, root, legends) {
      const dd = root.filter((d) => d.type == "leaf");
      const _this = this;
      for (let i in legends) {
        const { legendGroup, legendList, col, color, maxId } = legends[i];
        const legend = svg.append("g");
        legend
          .selectAll("rect")
          .data(dd)
          .enter()
          .append("rect")
          .attr("width", 50)
          .attr("height", 10)
          .attr("transform", (d, k) => {
            if (k == 0) {
              legend
                .append("text")
                .attr("dy", ".11em")
                .attr(
                  "transform",
                  `translate(0,-${
                    this.innerRadius + maxId.length * 7 + 30 + i * 80 + 75
                  })`
                )
                .text(col.label);
            }

            return `rotate(${d.x - 90}) translate(${
              this.innerRadius + maxId.length * 7 + 30 + i * 80 + 10
            },${d.x < 180 ? 3 : -3})`;
          })
          .attr("fill", (d) => {
            const td = _.find(this.tableData, (t) => t.key == d.name);
            if (td) return color(td[col.prop]);
          });
      }
    },
    linkVariable(d) {
      return this.linkStep(
        d.source.x,
        d.source.radius,
        d.target.x,
        d.target.radius
      );
    },
    linkConstant(d) {
      return this.linkStep(d.source.x, d.source.y, d.target.x, d.target.y);
    },
    computeExtendEndRadius() {
      let endRadius = this.innerRadius;
      if (this.isLeft) {
        endRadius = this.innerRadius + this.expendLineWidth;
      } else {
        endRadius =
          this.innerRadius + this.maxLableWidth + this.expendLineWidth;
        if (this.isSampleLabeWidth) {
          endRadius = this.innerRadius + this.labelWidth + this.expendLineWidth;
        }
      }
      return endRadius;
    },
    linkExtensionVariable(d) {
      let endRadius = this.computeExtendEndRadius();
      if (this.isSampleLabeWidth) {
      } else if (!this.isLeft) {
        endRadius = endRadius - Number(d.target.name.length * this.textWidth);
      }

      return this.linkStep(d.target.x, d.target.radius, d.target.x, endRadius);
    },
    linkExtensionConstant(d) {
      return this.linkStep(
        d.target.x,
        d.target.y,
        d.target.x,
        this.innerRadius
      );
    },
    linkStep(startAngle, startRadius, endAngle, endRadius) {
      const c0 = Math.cos((startAngle = ((startAngle - 90) / 180) * Math.PI));
      const s0 = Math.sin(startAngle);
      const c1 = Math.cos((endAngle = ((endAngle - 90) / 180) * Math.PI));
      const s1 = Math.sin(endAngle);
      return (
        "M" +
        startRadius * c0 +
        "," +
        startRadius * s0 +
        (endAngle === startAngle
          ? ""
          : "A" +
            startRadius +
            "," +
            startRadius +
            " 0 0 " +
            (endAngle > startAngle ? 1 : 0) +
            " " +
            startRadius * c1 +
            "," +
            startRadius * s1) +
        "L" +
        endRadius * c1 +
        "," +
        endRadius * s1
      );
    },
    drawTable(svg, root) {
      if (!this.tableOptions.showTable) {
        return;
      }
      const _this = this;
      const d = root.filter((d) => d.type == "leaf");
      const columns = _.filter(this.columns, (t) => !t.hide);
      if (columns.length < 1) {
        return;
      }
      const data = this.tableData;
      const fontSize = this.options.fontSize;
      const w = this.computedLeftCreat();

      for (let i in columns) {
        const c = columns[i];
        const textSvg = svg
          .append("g")
          .selectAll("text")
          .data(d)
          .enter()
          .append("text");

        textSvg
          .attr("dy", ".11em")
          .attr("font-size", fontSize)
          .attr(
            "transform",
            (d) =>
              `rotate(${d.x - 90}) translate(${
                d.y +
                w +
                _this.tableOptions.left +
                ((150 * fontSize) / 9) * i +
                4
              },${d.x < 180 ? 3 : -3})${d.x < 180 ? " " : " rotate(180)"}`
          )
          .attr("text-anchor", (d) => (d.x < 180 ? "start" : "end"))
          .text((d, k) => {
            if (
              (k == 0 && _this.angle < 0) ||
              (k == data.length - 1 && _this.angle >= 0)
            ) {
              const thsvg = svg.append("g").append("text");
              thsvg
                .attr("dy", ".11em")
                .attr("font-size", fontSize)
                .attr("text-anchor", d.x < 180 ? "start" : "end")
                .attr(
                  "transform",
                  `rotate(${d.x - 90}) translate(${
                    d.y +
                    w +
                    _this.tableOptions.left +
                    ((150 * fontSize) / 9) * i +
                    10
                  },${-15})`
                )
                .attr("font-weight", "bold")
                .text(c.label);
            }
            const td = _.find(data, (t) => t.key == d.name);
            if (td) {
              return td[c.prop];
            }
          });
      }
    },
  },
  computed: {
    expendLineWidth() {
      return this.options.expendLineWidth;
    },
    isLeft() {
      return this.options.isLeft;
    },
    isSampleLabeWidth() {
      return this.options.isSampleLabeWidth;
    },
    isSameLength() {
      return this.options.isSameLength;
    },
    labelWidth() {
      return this.options.labelWidth;
    },
    angle() {
      return this.options.angle;
    },
    okData() {
      return this.dealNwk();
    },
    innerRadius() {
      return this.options.r;
    },
  },
  name: "circle-chart",
  mixins: [basemixin],
};
</script>

<style></style>
