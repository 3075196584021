import JLTree from "./JLTree.vue";
import JLTreeNew from "./JLTree-New.vue";
import GTree from "./GrapeTree.vue";
import Phylogram from "./Phylogram";
import DTree from "./d-tree-show";
import setcolumn from "./set-column";

const all = {
  install(Vue) {
    if (this.installed) return;
    this.installed = true;
    Vue.component(JLTree.name, JLTree);
    Vue.component(JLTreeNew.name, JLTreeNew);
    Vue.component(GTree.name, GTree);
    Vue.component(Phylogram.name, Phylogram);
    Vue.component(DTree.name, DTree);
    Vue.component(setcolumn.name, setcolumn);
  },
};

export default all;
