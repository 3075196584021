import Newick from "./newick.js";

export function buildNewickNodes(node, callback) {
  var newickNodes = [];
  const fun = (n, c) => {
    newickNodes.push(n);
    if (n.branchset) {
      for (var i = 0; i < n.branchset.length; i++) {
        fun(n.branchset[i]);
      }
    }
  }
  fun(node, callback)
  return newickNodes;
}



export function buildNewick(text) {
  const newick = Newick.parse(text);
  const newickNodes = buildNewickNodes(newick);
  return { newick, newickNodes }
}

export function retrieveIdList(newickNodes) {
  var idlist = newickNodes.filter(filterLeaf).map(function (node) {
    return  node.name ;
  });
  return idlist;
}

export function filterLeaf(node) {
  return !node.branchset;
}

export function loadMetaFile(url) {
  return Q.nfcall(d3.json, url);
}