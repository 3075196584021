import base from "./components/base";
import JLTreeExample from "./components/JLTreeExample.vue";


const all = {
    install(Vue) {
        Vue.use(base)
        Vue.component(JLTreeExample.name, JLTreeExample);
    }
}

export default all