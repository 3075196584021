<template>
  <div id="app">
    <table class="xin-tabel">
      <tr>
        <th>width:</th>
        <td><input v-model="width" title="宽度" /></td>
        <th>space:</th>
        <td><input v-model="space" title="key间隔宽度" /></td>
        <th>top:</th>
        <td><input v-model="top" title="整体与顶部偏移" /></td>
        <th>left:</th>
        <td><input v-model="left" title="整体与左部偏移" /></td>
        <th>labelWidth:</th>
        <td><input v-model="labelWidth" title="label的宽度" /></td>
      </tr>
      <tr>
        <th>showLabel:</th>
        <td><input v-model="showLabel" title="是否显示label" /></td>
        <th>showGrid_Label:</th>
        <td><input v-model="showGrid_Label" title="是否显示label网格线" /></td>
        <th>showGrid_Value:</th>
        <td><input v-model="showGrid_Value" title="是否显示value网格线" /></td>
        <th>showMaxLine:</th>
        <td>
          <input v-model="showMaxLine" title="是否显示数据最大值标识线" />
        </td>
      </tr>
      <tr>
        <th>pointSize:</th>
        <td><input v-model="pointSize" title="调整标识点的大小" /></td>
        <th>lineWeight:</th>
        <td><input v-model="lineWeight" title="调整连线的粗细" /></td>
        <th>pointColor:</th>
        <td><input v-model="pointColor" title="调整标识点的颜色" /></td>
        <th>lineColor:</th>
        <td><input v-model="lineColor" title="调整连线的颜色" /></td>
        <th>maxLineColor:</th>
        <td><input v-model="maxLineColor" title="最大值标识线的颜色" /></td>
      </tr>
      <tr>
        <th>valueTitle:</th>
        <td><input v-model="valueTitle" title="value值的标题" /></td>
        <th>valueTitleLeft:</th>
        <td><input v-model="valueTitleLeft" title="value值的标题左偏移" /></td>
        <th>maxValue:</th>
        <td><input v-model="maxValue" title="坐标显示最大value" /></td>
        <td colspan="4">
          <button @click="getlabel">获取label到控制台（getlabel）</button>
          <button @click="savenwk">保存nwk（savenwk）</button>
          <button @click="savesvg">保存svg（savesvg）</button>
        </td>
      </tr>
      <tr>
        <th>nwk:</th>
        <td colspan="9">
          <textarea v-model="nwk" rows="6" style="width: 100%" title="传入数据格式" />
        </td>
      </tr>
    </table>

    <hr />
    <div style="width: 100%">
      <jltree
        ref="jlshow"
        :nwk="nwk"
        :width="Number(width)"
        :pointSize="Number(pointSize)"
        :lineWeight="Number(lineWeight)"
        :lineColor="lineColor"
        :pointColor="pointColor"
        :maxLineColor="maxLineColor"
        :space="Number(space)"
        :top="Number(top)"
        :left="Number(left)"
        :maxValue="Number(maxValue)"
        :valueTitle="valueTitle"
        :valueTitleLeft="Number(valueTitleLeft)"
        :labelWidth="Number(labelWidth)"
        :showLabel="showLabel == 'true' || showLabel == true"
        :showGrid_Label="showGrid_Label == 'true' || showGrid_Label == true"
        :showGrid_Value="showGrid_Value == 'true' || showGrid_Value == true"
        :showMaxLine="showMaxLine == 'true' || showMaxLine == true"
        containerName="xin-jl-chart"
        :selectTable="selectTable"
        :column="column"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";

let JLData = [
  [["a8"], ["fdc34"], 40, 2],
  [["m56"], ["m57"], 20, 2],
  [["a2"], ["b6"], 60, 2],
  [["b5"], ["c6"], 100, 2],
  [["a8", "fdc34"], ["m56", "m57"], 50, 4],
  [["b5", "c6"], ["a2", "b6"], 100, 4],
  [["a8", "fdc34", "m56", "m57"], ["b5", "c6", "a2", "b6"], 100, 8],
  [["a1"], ["h2"], 120, 2],
  [["a1", "h2"], ["a8", "fdc34", "m56", "m57", "a2", "b6", "b5", "c6"], 150, 10],
  [["nj1"], ["nh2"], 180, 2],
  [
    ["nj1", "nh2"],
    ["a1", "h2", "m56", "m57", "a8", "fdc34", "b5", "c6", "a2", "b6"],
    180,
    12,
  ],
  [["nj3"], ["nh4"], 190, 2],
  [
    ["nj3", "nh4"],
    ["nj1", "nh2", "a1", "h2", "m56", "m57", "a8", "fdc34", "b5", "c6", "a2", "b6"],
    200,
    14,
  ],
];
export default {
  name: "jltree-example",
  data() {
    return {
      nwk:
        "(((((s6A:23.00,98A:23.00):26.00,s2A:49.00):220.00,(((((s5A:2.00,s1A:2.00):0.00,s52A:2.00):7.00,s9A:9.00):6.00,s81A:15.00):12.00,s7A:27.00):242.00):106.00,(s16A:47.00,s10A:47.00):328.00):2391.00,(s66A:215.00,s15A:215.00):2551.00);",
      // nwk:
      //   "((((SD_11:21.00,SX08:21.00):2709.00,Sal_test004:2730.00):31.00,(SD_03:12.00,SD_05:12.00):2749.00):91.00,Sal_test003:2852.00);",

      pointSize: 4,
      lineWeight: 1,
      lineColor: "black",
      pointColor: "black",
      maxLineColor: "red",
      width: 400,
      space: 30,
      top: 50,
      left: 35,
      maxValue: 70,
      labelWidth: 130,
      showLabel: false,
      showGrid_Label: false,
      showGrid_Value: false,
      showMaxLine: true,
      valueTitle: "位点差异个数",
      valueTitleLeft: 80,
      Table: [
        {
          caozuo: "",
          st: "",
          name: "某细菌手头氏菌分的是是是",
          code: "AAAAAAAAAAAAAAAA",
          year: "",
          fen1li2lai2yuan2: "",
          pos: "",
          dept: "",
          remark: "",
          Stuff: "",
          "": "",
          update_time: "2020-11-04 09:51:26",
          create_time: "2020-11-03 10:41:00",
          sharedMarkers: 0,
          oldfile: "否",
          splicing_analysis: "否",
          virulence_analysis: "否",
          resistance_analysis: "否",
          salmonella_analysis: "否",
          MLST_analysis: "否",
          cgMLST_analysis: "否",
          Strain_Identification_analysis: "否",
          pjfile: "否",
          id: "5fa0c33c8df36b16c6b49530",
          key: "F4",
          owner: {
            uid: "1323095143089852417",
            uname: "code",
            deptid: 1,
            dept: "",
            orgname: "",
          },
          strain_type: 5,
          expand_value: {
            caozuo: "",
            st: "",
            name: "AAAAAAAAAAAAAAAAA",
            code: "",
            year: "",
            fen1li2lai2yuan2: "",
            pos: "",
            dept: "",
            remark: "",
            Stuff: "",
            "": "",
            update_time: "2020-11-04 09:51:19",
            create_time: "2020-11-03 10:41:00",
            sharedMarkers: 0,
            oldfile: "否",
            splicing_analysis: "否",
            virulence_analysis: "否",
            resistance_analysis: "否",
            salmonella_analysis: "否",
            MLST_analysis: "否",
            cgMLST_analysis: "否",
            Strain_Identification_analysis: "否",
            pjfile: "否",
          },
          $index: 0,
        },
      ],
    };
  },
  computed: {
    selectTable() {
      const str = window.localStorage.getItem("doublexin_selectTable");
      const res = str ? JSON.parse(str) : [];
      return res;
      const d = this.Table[0];
      const labels = [
        "h76",
        "h75",
        "h63",
        "h62",
        "h73",
        "h71",
        "h70",
        "h69",
        "h68",
        "h67",
        "h60",
        "h59",
        "h65",
        "h64",
        "h57",
        "h55",
        "h58",
        "h72",
        "h66",
        "h56",
        "h61",
        "h74",
      ];
      return _.map(labels, (t) => ({
        ...d,
        key: t,
      }));
    },
    column() {
      return [
        {
          label: "Key",
          prop: "key",
          width: 100,
        },
        {
          label: "ST型",
          prop: "st",
          width: 100,
        },
        // {
        //   prop: "name",
        //   label: "菌株名称",
        //   width: 200,
        // },
        // {
        //   prop: "code",
        //   label: "菌株编号",
        //   width: 200,
        // },
        // {
        //   prop: "year",
        //   label: "分离年代",
        //   width: 100,
        // },
        // {
        //   prop: "fen1li2lai2yuan2",
        //   label: "分离来源",
        //   width: 100,
        // },
        // {
        //   prop: "pos",
        //   label: "分离地点",
        //   width: 100,
        // },
        // {
        //   prop: "dept",
        //   label: "提供单位",
        //   width: 100,
        // },
        // {
        //   prop: "remark",
        //   label: "备注",
        //   width: 100,
        // },
        // {
        //   prop: "Stuff",
        //   label: "实验人员",
        //   width: 100,
        // },
      ];
    },
  },
  updated() {
    this.$refs.jlshow.init();
  },
  methods: {
    getlabel() {
      console.log(this.$refs.jlshow.getLabel());
    },
    savenwk() {
      this.$refs.jlshow.savenwk();
    },
    savesvg() {
      this.$refs.jlshow.savesvg();
    },
  },
};
</script>

<style scoped>
.xin-table th {
  text-align: right;
}
</style>
