<template>
  <el-button-group>
    <!-- <el-button type="primary" size="small" @click="savenwk"
      >另存为Newick</el-button
    > -->
    <el-button type="primary" size="small" @click="savesvg"
      >另存为SVG</el-button
    >
    <el-button type="primary" size="small" @click="savepdf"
      >另存为PDF</el-button
    >
  </el-button-group>
</template>

<script>
export default {
  methods: {
    savenwk() {
      this.$emit("savenwk");
    },
    savesvg() {
      this.$emit("savesvg");
    },
    savepdf() {
      this.$emit("savepdf");
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
