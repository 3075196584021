import * as d3 from "d3";
import * as svgPanZoom from "svg-pan-zoom";
import { saveTextAsFile, saveAsPdf } from "@/assets/js/help";
import { buildNewick, retrieveIdList } from "@/assets/js/datasource.js";

export default {
  data() {
    return {
      height: 0,
      width: 0,
      maxLableWidth: 0,
      // textWidth: 5,
    };
  },
  computed: {
    textWidth() {
      return Math.ceil((this.options.fontSize * 5) / 9);
    },
    isJL() {
      return this.type === "jl";
    },
  },
  methods: {
    /**
     *给指定id的svg,绑定放大缩小
     * @param {*} id
     */
    bindZoom(id) {
      window.zoomTiger = svgPanZoom("#" + id, {
        zoomEnabled: true,
        controlIconsEnabled: false,
        fit: true,
        center: true,
        minZoom: 0.08,
        maxZoom: 100,
      });
    },
    getSize() {
      let main = document.getElementById(this.containerName);
      const height = main.clientHeight;
      const width = main.clientWidth;
      this.height = height;
      this.width = width;
      return { height, width };
    },
    dealNwk() {
      const { newick, newickNodes } = buildNewick(this.nwk);
      const idlist = retrieveIdList(newickNodes);
      const maxId = _.maxBy(idlist, (t) => t.length);
      return { newick, newickNodes, idlist, maxId };
    },
    clear() {
      document.getElementById(this.containerName).innerHTML = "";
      document.getElementById(`${this.containerName}1`).innerHTML = "";
    },
    mouseovered(active) {
      return function (d) {
        d3.select(this).classed("label--active", active);
        d3.select(d.linkExtensionNode).classed(
          "link-extension--active",
          active
        );
        do d3.select(d.linkNode).classed("link--active", active);
        while ((d = d.parent));
      };
    },
    creatColorScale(data) {
      const range = data.colors;
      let color = d3.scale.ordinal().domain(data.group).range(range);
      return color;
    },
    hasChina(str) {
      if (!str) {
        return false;
      }
      const res = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      return res.test(str);
    },
    /**
     *
     * @returns 计算出表格left开始位置
     */
    computedLeftCreat() {
      const dd = _.filter(this.groupTabs, (t) => t.style != "point" && t.show);
      const maxLeft = _.maxBy(dd, (t) => t.left);
      const now = 0 + this.options.labelWidth + this.options.expendLineWidth;
      if (!maxLeft) {
        return now;
      }
      const { type, style, left, w, r, space, column } = maxLeft;
      if (type == "one" && style == "rect") {
        return left + w * 2 + space;
      }
      if (type == "one" && style == "circle") {
        return left + r * 2 * 2 + space;
      }
      if (type == "more" && style == "rect") {
        return left + (w + space) * (column.length + 1) + 5;
      }
      if (type == "more" && style == "circle") {
        return left + (2 * r + space) * (column.length + 1) + 5;
      }
    },
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          isCircle: true,
          expendLineWidth: 0,
          isLeft: false,
          isSampleLabeWidth: false,
          labelWidth: 100,
          angle: 360,
        };
      },
    },
    tableOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    containerName: {
      type: String,
      default: "xin-chart",
    },
    nwk: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    groupTabs: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: "jl",
    },
  },
};
