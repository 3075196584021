<template>
  <div :id="containerName"></div>
</template>
<script>
import * as d3 from "d3";
import d3Tip from "d3-tip";
import _ from "lodash";
import { saveTextAsFile, saveAsPdf } from "../static/js/help";

export default {
  name: "jltree",
  data() {
    return {
      height: 0, //内置
      lableAxis: [],
      showData: [],
      JLData: [],
      rightWidth: 0,
      allWidth: 0,
      _columns: [],
    };
  },
  props: {
    defaultColumnWidth: {
      type: Number,
      default: 150,
    },
    width: {
      type: Number,
      default: 600,
    },
    space: {
      type: Number,
      default: 30,
    },
    top: {
      type: Number,
      default: 50,
    },
    left: {
      type: Number,
      default: 20,
    },
    labelWidth: {
      type: Number,
      default: 130,
    },
    pointSize: {
      type: Number,
      default: 4,
    },
    lineWeight: {
      type: Number,
      default: 2,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showMaxLine: {
      type: Boolean,
      default: true,
    },
    showGrid_Value: {
      type: Boolean,
      default: false,
    },
    showGrid_Label: {
      type: Boolean,
      default: false,
    },
    valueTitleLeft: {
      type: Number,
      default: 80,
    },
    maxValue: {
      type: Number,
      default: 200,
    },
    valueTitle: {
      type: String,
      default: "位点差异个数",
    },
    lineColor: {
      type: String,
      default: "black",
    },
    pointColor: {
      type: String,
      default: "black",
    },
    maxLineColor: {
      type: String,
      default: "red",
    },
    containerName: {
      type: String,
      required: true,
      default: "xin-jl-chart",
    },
    nwk: {
      type: String,
      default: "",
    },
    column: {
      type: Array,
      default() {
        return [
          {
            prop: "key",
            label: "Key",
          },
        ];
      },
    },
    selectTable: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {},
  mounted() {
    if (!this.nwk || this.nwk.indexOf("(") < 0) {
      console.log("数据格式不合法");
      return;
    }
    this.init();
  },
  computed: {},
  methods: {
    savenwk() {
      saveTextAsFile(this.nwk, "Jtree.nwk");
    },
    savesvg() {
      var svgData = document.getElementById("lx_jtree_svg_" + this.containerName)
        .outerHTML;
      var svgData = [
        '<svg xmlns="http://www.w3.org/2000/svg" ',
        svgData.substring(5, 9999999),
      ].join("");
      saveTextAsFile(svgData, "Jtree.svg");
    },
    getsvg() {
      let svg = document.getElementById("lx_jtree_svg_" + this.containerName);
      let svgData = svg.outerHTML;
      const w = svg.clientWidth;
      const h = svg.clientHeight;
      svgData = [
        '<svg xmlns="http://www.w3.org/2000/svg" ',
        svgData.substring(5, 9999999),
      ].join("");
      return {
        svg: svgData,
        w,
        h,
      };
    },
    savepdf() {
      let svg = document.getElementById("lx_jtree_svg_" + this.containerName);
      let svgData = svg.outerHTML;
      const w = svg.clientWidth;
      const h = svg.clientHeight;
      svgData = [
        '<svg xmlns="http://www.w3.org/2000/svg" ',
        svgData.substring(5, 9999999),
      ].join("");
      saveAsPdf(svgData, "Jtree", w, h);
    },
    countWidth() {
      if (this.column.length >= 1) {
        const cc = [];
        for (const i in this.column) {
          const t = this.column[i];
          const max = d3.max(this.selectTable, (d) => {
            const l = t.label.length + 1;
            const ss = d[t.prop];
            if (!ss) {
              return l;
            }
            const l_all = ss.length;
            const en = String(ss).replace(/[\u4e00-\u9fa5]/g, "");
            const l_en = en.length;
            const l_cn = l_all - l_en;
            const l_end = l_cn + (l_en + 1) * 0.75;
            if (l < l_end) return l_end;
            return l;
          });

          cc.push({ ...t, width: (max * 50) / 3 });
        }
        this._columns = cc;
      }
      if (this.column.length >= 1) {
        const defaultw = this.defaultColumnWidth;
        this.rightWidth =
          100 +
          _.sumBy(this._columns, (t) => {
            return t.width || defaultw;
          });
      } else {
        this.rightWidth = this.labelWidth;
      }
      this.allWidth = this.rightWidth + this.width;
    },
    getLabel() {
      return this.lableAxis;
    },
    clear() {
      this.showData = [];
      d3.selectAll("svg > *").remove();
      d3.select("svg").remove();
    },
    init() {
      this.countWidth();
      d3.select("#" + this.containerName)
        .style("width", this.allWidth + "px")
        .style("margin", "0 auto");
      this.clear();
      this.dealNwkData(this.nwk);
      this.XAxis = this.lableAxis;
      this.countHeight(this.lableAxis.length);
      let svg = this.drawSvg();
      const X = this.drawValueAxis(svg);
      const Y = this.drawlabelAxis(svg);
      const X2 = this.drawTableColumn(svg);
      this.drawTableData(svg, Y);
      this.drawMaxLine(svg, X, Y);
      let tip = d3Tip() // 设置tip
        .attr("class", "d3-tip")
        .style("background", "rgba(0,0,0,0.5)")
        .style("padding", "10px")
        .style("color", "rgb(255,255,255)")
        .offset([40, 0])
        .html(function (d) {
          const dd = d.srcElement.__data__;
          const _max = d3.max(dd, (v) => v.x);
          return "<strong>位点差异： " + _max + "</span>";
        });
      svg.call(tip);
      this.drawPath(this.showData, svg, X, Y);
      this.drawPoint(this.showData, svg, X, Y);
      if (this.showGrid_Label) {
        this.drawGridLabel();
      }
      if (this.showGrid_Value) {
        this.drawGridValue();
      }
      this.drawLabelPoint();
    },
    drawPath(data, svg, X, Y) {
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      let line = d3.svg
        .line()
        .x(function (d) {
          return X(d.x);
        })
        .y(function (d) {
          return (Y(d.y1) + Y(d.y2)) / 2;
        });
      const color = this.lineColor;
      let serie = svg
        .selectAll(".serie") // 生成线条
        .data(data)
        .enter()
        .append("g")
        .attr("class", "serie");
      serie
        .append("path") // 绘画线条
        .attr("class", "line")
        .attr("stroke", color)
        .attr("stroke-width", this.lineWeight)
        .attr("fill", "none")
        .attr("d", line)
        .attr("transform", "translate(" + this.left + "," + h + ")");
    },
    dealNwkData(data) {
      let list = {};
      let res = [];
      const getv = (str) => {
        if (!str) {
          return [0, 0];
        }
        const value = _.map(str.match(/:([0-9]{1,})?(\.[0-9]{1,})?|:([0-9]{1,})/g), (t) =>
          Number(t.replace(":", ""))
        );
        return value;
      };
      const getmax = (str) => {
        if (!str) {
          return 0;
        }
        const value = getv(str);
        const max = d3.max(value);
        return max;
      };
      const getmin = (str) => {
        if (!str) {
          return 0;
        }
        const value = getv(str);
        const min = d3.min(value);
        return min;
      };
      let fun = (a) => {
        const s = a.lastIndexOf("(") + 1;
        const e = a.substring(s).indexOf(")") + s;
        //不带括号字符串
        let str = a.substring(s, e);
        //带括号字符串
        const str1 = a.substring(s - 1, e + 1);
        //合成名字
        const name = str
          .replace(/:([0-9]{1,})?(\.[0-9]{1,})?|:([0-9]{1,})/g, "")
          .replace(",", "***");
        const names = str.replace(/:([0-9]{1,})?(\.[0-9]{1,})?|:([0-9]{1,})/g, "");
        let name1 = names.split(",")[0];

        const max1 = getmax(list[name1]);

        let name1arr = name1.split("***");

        let name2 = names.split(",")[1];
        const max2 = getmax(list[name2]);

        let name2arr = name2.split("***");

        const max12 = d3.max([max1, max2]);
        let v = getv(str);
        v[0] = v[0] + max1;
        v[1] = v[1] + max2;
        let max = d3.max(v);
        str = `${name1}:${max},${name2}:${max}`;
        if (max > this.maxValue) {
          max = this.maxValue;
        }
        res.push([name1arr, name2arr, max, name1arr.length + name2arr.length]);
        list[name] = str;
        const ss = a.replace(str1, name);
        if (ss.indexOf(",") >= 0) {
          fun(ss);
        }
      };
      fun(data);
      this.JLData = res;
      this.dealRealData(res);
    },
    dealRealData(data) {
      let xx = [];
      for (var i in data) {
        const t = data[i];
        const t0 = t[0];
        const t1 = t[1];
        let dd = [];

        if (t[3] == 2) {
          dd.push({ x: 0, y1: t0[0], y2: t0[0] });
          dd.push({ x: t[2], y1: t0[0], y2: t0[0] });
          dd.push({ x: t[2], y1: t1[0], y2: t1[0] });
          dd.push({ x: 0, y1: t1[0], y2: t1[0] });
          xx.push(t0[0]);
          xx.push(t1[0]);
        } else {
          const t0l = t0.length;
          const t1l = t1.length;
          if (t0l >= 2) {
            const dd0 = _.find(data, (d) => d[3] == t0l && d[0].indexOf(t0[0]) >= 0);
            dd.push({ x: dd0[2], y1: t0[0], y2: t0[t0l - 1] });
            dd.push({ x: t[2], y1: t0[0], y2: t0[t0l - 1] });
          } else {
            dd.push({ x: 0, y1: t0[0], y2: t0[0] });
            dd.push({ x: t[2], y1: t0[0], y2: t0[0] });
            xx.push(t0[0]);
          }
          if (t1l >= 2) {
            const dd1 = _.find(data, (d) => d[3] == t1l && d[0].indexOf(t1[0]) >= 0);
            dd.push({ x: t[2], y1: t1[0], y2: t1[t1l - 1] });
            dd.push({ x: dd1[2], y1: t1[0], y2: t1[t1l - 1] });
          } else {
            dd.push({ x: t[2], y1: t1[0], y2: t1[0] });
            dd.push({ x: 0, y1: t1[0], y2: t1[0] });
            xx.push(t1[0]);
          }
        }
        this.showData.push(dd);
      }
      this.lableAxis = xx;
    },
    countHeight(lableCount) {
      this.height = this.space * lableCount + this.top;
    },
    drawSvg() {
      let main = d3.select("#" + this.containerName);
      let svg = main
        .append("svg")
        .attr("id", "lx_jtree_svg_" + this.containerName)
        .attr("width", this.allWidth + "px")
        .attr("height", this.height + "px");
      return svg;
    },
    drawValueAxis(svg) {
      const X = d3.scale
        .linear()
        .domain([0, this.maxValue])
        .rangeRound([this.allWidth - this.rightWidth - 10, 0]);
      const axisX = d3.svg.axis().scale(X).orient("top").ticks(10);

      svg
        .append("g")
        .call(axisX)
        .attr("transform", "translate(" + this.left + "," + this.top + ")")
        .attr("class", "axis axis--x");
      d3.select(".axis--x")
        .select("path")
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("fill", "none");
      return X;
    },
    drawlabelAxis(svg) {
      const Y = d3.scale
        .ordinal()
        .domain(this.XAxis)
        .rangeBands([0, this.height - this.top - 10]);
      const axisY = d3.svg.axis().scale(Y).orient("right");
      if (!this.showLabel) {
        axisY.tickFormat(function () {
          return "";
        });
      }
      const h = (this.height - this.top - 10) / this.lableAxis.length / 2 + this.top;
      svg
        .append("g")
        .call(axisY)
        .attr(
          "transform",
          "translate(" +
            (this.allWidth - this.rightWidth + this.left - 10) +
            "," +
            this.top +
            ")"
        )
        .attr("class", "axis axis--y")
        .append("text")
        .attr("y", -35)
        .attr("x", -(this.allWidth - this.rightWidth + this.left - this.valueTitleLeft))
        // .attr("dy", ".71em")
        .style("text-anchor", "middle")
        .attr("fill", "#000")
        .text(this.valueTitle);

      d3.select(".axis--y").select("path").remove();
      // .attr("stroke", "#000")
      // .attr("stroke-width", 1)
      // .attr("fill", "none");
      return Y;
    },
    drawTableColumn(svg) {
      if (this.column.length < 1) {
        return;
      }
      let last_w = this.left + this.allWidth - this.rightWidth + 10;
      const fun = (t, i, w) => {
        //定义已经使用的宽度
        const _w = w;
        //定义列宽
        const _cw = t.width || this.defaultColumnWidth;
        const X = d3.scale.ordinal().domain([t.label]).rangeBands([0, _cw]);
        const axisX = d3.svg.axis().scale(X).orient("top");
        svg
          .append("g")
          .call(axisX)
          .attr("transform", "translate(" + _w + "," + this.top + ")")
          .attr("class", "axis axis--x2_" + i);
        d3.select(".axis--x2_" + i)
          .select("path")
          .remove();
        // .attr("stroke", "black")
        // .attr("stroke-width", 1)
        // .attr("fill", "none");
        d3.select(".axis--x2_" + i)
          .select("g")
          .attr("transform", "translate(0,0)");
        d3.select(".axis--x2_" + i)
          .select("g")
          .append("rect")
          .attr("class", "lx-table-header")
          .attr("width", i == 0 ? _cw + 10 : _cw)
          .attr("height", 26)
          .attr("fill", "rgb(0, 0, 0)")
          .attr("fill-opacity", 0.1)
          .attr("strokeWidth", 0)
          // .attr("stroke", "rgb(0, 0, 0)")
          .attr("transform", "translate(" + (i == 0 ? -10 : 0) + ",-26)");
        d3.select(".axis--x2_" + i)
          .select("text")

          .style("text-anchor", "start");
        // .style("padding-left", "5px");
        last_w = _w + _cw;
      };
      for (const i in this._columns) {
        let t = this._columns[i];
        fun(t, i, last_w);
      }
    },
    drawTableData(svg, Y) {
      if (this.column.length < 1 || this.selectTable.length < 1) {
        return;
      }
      const h = (this.height - this.top - 10) / this.lableAxis.length / 2 + this.top;
      // const rightWidth = this.rightWidth - this.valueTitleLeft - 10;
      const rightWidth = this.allWidth - this.labelWidth + this.left + 5;
      const leftWidth = this.allWidth - this.rightWidth + 10;

      const fun = (d1, i1) => {
        const y = Y(d1.key);
        let last_w = this.left + this.allWidth - this.rightWidth + 10;
        const fun_c = (t, d, i, w) => {
          //定义已经使用的宽度
          const _w = w;
          //定义列宽
          const _cw = t.width || this.defaultColumnWidth;
          const X = d3.scale.ordinal().domain([d]).rangeBands([0, _cw]);
          const axisX = d3.svg.axis().scale(X).orient("top");
          svg
            .append("g")
            .call(axisX)
            .attr("transform", "translate(" + _w + "," + (this.top + y - 4 + h / 2) + ")")
            .attr("class", "axis axis--x23_" + i1 + "_" + i);
          d3.select(".axis--x23_" + i1 + "_" + i)
            .select("path")
            .remove();
          // .attr("stroke", "black")
          // .attr("stroke-width", 1)
          // .attr("fill", "none");
          d3.select(".axis--x23_" + i1 + "_" + i)
            .select("g")
            .on("click", function () {
              const obj = d3.select(".axis--x23_" + i1 + "_" + 0).select("rect")[0][0];
              if (!!obj) {
                d3.selectAll(".lx-table-data").remove();
                return;
              }
              d3.selectAll(".lx-table-data").remove();
              d3.select(".axis--x23_" + i1 + "_" + 0)
                .select("g")
                .append("rect")
                .attr("class", "lx-table-data")
                .attr("width", rightWidth)
                .attr("height", 26)
                .attr("fill", "rgb(0, 0, 255)")
                .attr("fill-opacity", 0.1)
                .attr("strokeWidth", 0)
                // .attr("stroke", "rgb(0, 0, 0)")
                .attr("transform", "translate(-" + leftWidth + ",-26)");
            })
            .attr("transform", "translate(" + 0 + ",0)");
          d3.select(".axis--x23_" + i1 + "_" + i)
            .select("text")
            .style("text-anchor", "start");
          // .attr("stroke", "black")
          // .attr("stroke-width", 1)
          // .attr("fill", "none");
          last_w = _w + _cw;
        };
        const colum = _.map(this._columns, (t) => d1[t.prop] || "-");
        for (const i in this._columns) {
          let t = this._columns[i];
          fun_c(t, colum[i], i, last_w);
        }
      };

      const data = this.selectTable;
      for (let i in data) {
        const t = data[i];
        fun(t, i);
      }
    },
    drawMaxLine(svg, X, Y) {
      if (!this.showMaxLine) {
        return;
      }
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      const max = d3.max(this.JLData, (t) => t[2]);
      let maxLine = svg
        .append("line")
        .attr("class", "max-line")
        .attr("stroke", this.maxLineColor)
        .attr("stroke-width", this.lineWeight)
        .attr("stroke-dasharray", "10,10")
        .attr("transform", "translate(" + this.left + "," + this.top + ")")
        .attr("y1", 0)
        .attr("x1", X(max))
        .attr("y2", this.yLength() - 2 * h)
        .attr("x2", X(max));
      svg
        .append("text")
        .attr("class", "label")
        .attr("font-size", 15)
        .attr("fill", this.maxLineColor)
        .text(max)
        .attr("x", X(max + 0.5))
        .attr("y", 23)
        .attr(
          "transform",
          "translate(" + this.left + "," + h + ")rotate(-90," + X(max + 0.5) + ",23)"
        );
    },
    get_max() {
      const max = d3.max(this.JLData, (t) => t[2]);
      return max;
    },
    drawPoint(data, svg, X, Y) {
      const textX = 0.3;
      const textY = -5;
      const _this = this;
      const h = this.yLength() / this.lableAxis.length / 2 + this.top;
      const max = d3.max(this.JLData, (t) => t[2]);
      var node = svg
        .selectAll(".node")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "node");

      node
        .append("circle")
        .attr("r", this.pointSize)
        .attr("fill", this.pointColor)
        .attr("transform", "translate(" + this.left + "," + h + ")")
        // .style("z-index", "10")
        .attr("cx", function (d) {
          const _min = d3.min(d, (t) => t.x);
          if (_min == 0 || _min == max) {
            return 9999;
          }
          return X(_min);
        })
        .attr("cy", function (d) {
          const _min = d3.min(d, (t) => t.x);
          const dmin = _.filter(d, (t) => t.x == _min);
          return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2;
        });

      node
        .append("text")
        .attr("class", "label")
        .attr("font-size", 10)
        // .style("transform", "translate(" + this.left + "px," + h + "px)")
        .text(function (d) {
          const _min = d3.min(d, (t) => t.x);
          if (_min == 0 || _min == max) {
            return "";
          }
          return _min;
        })
        .attr("x", function (d) {
          const _min = d3.min(d, (t) => t.x);
          return X(_min + textX);
        })
        .attr("y", function (d) {
          const _min = d3.min(d, (t) => t.x);
          const dmin = _.filter(d, (t) => t.x == _min);
          return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY;
        })
        .attr("transform", function (d) {
          const _min = d3.min(d, (t) => t.x);
          // const _min = d3.min(d, (t) => t.x);
          const dmin = _.filter(d, (t) => t.x == _min);
          const str =
            "translate(" +
            _this.left +
            "," +
            h +
            ")rotate(-90," +
            X(_min + textX) +
            "," +
            ((Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY) +
            ")";
          return str;
        });

      node
        .append("circle")
        .attr("r", this.pointSize)
        .attr("fill", this.pointColor)
        .attr("transform", "translate(" + this.left + "," + h + ")")
        // .style("z-index", "10")
        .attr("cx", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);

          if (dd && dd.length > 0) {
            if (dd[0].x == 0 || dd[0].x == max) {
              return 9999;
            }
            return X(dd[0].x);
          }
          if (_min == 0 || _min == max) {
            return 9999;
          }
          return X(_min);
        })
        .attr("cy", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return (Y(dd[0].y1) + Y(dd[0].y2)) / 2;
          }
          const dmin = _.filter(d, (t) => t.x == _min);
          if (dmin.length > 1) {
            return (Y(dmin[1].y1) + Y(dmin[1].y2)) / 2;
          }
          return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2;
        });

      node
        .append("text")
        .attr("class", "label")
        .attr("font-size", 10)
        .text(function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return dd[0].x;
          }
          if (_min == 0 || _min == max) {
            return "";
          }
          return _min;
        })
        .attr("x", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return X(dd[0].x + textX);
          }
          return X(_min + textX);
        })
        .attr("y", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          if (dd && dd.length > 0) {
            return (Y(dd[0].y1) + Y(dd[0].y2)) / 2 + textY;
          }
          const dmin = _.filter(d, (t) => t.x == _min);
          if (dmin.length > 1) {
            return (Y(dmin[1].y1) + Y(dmin[1].y2)) / 2 + textY;
          }
          return (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY;
        })
        .attr("transform", function (d) {
          const _max = d3.max(d, (t) => t.x);
          const _min = d3.min(d, (t) => t.x);
          const dd = _.filter(d, (t) => t.x > _min && t.x < _max);
          let x = 0,
            y = 0;
          if (dd && dd.length > 0) {
            x = X(dd[0].x + textX);
            y = (Y(dd[0].y1) + Y(dd[0].y2)) / 2 + textY;
          } else {
            const dmin = _.filter(d, (t) => t.x == _min);
            if (dmin.length > 1) {
              y = (Y(dmin[1].y1) + Y(dmin[1].y2)) / 2 + textY;
            } else {
              y = (Y(dmin[0].y1) + Y(dmin[0].y2)) / 2 + textY;
            }
            x = X(_min + textX);
          }
          const str =
            "translate(" + _this.left + "," + h + ")rotate(-90," + x + "," + y + ")";
          return str;
        });
    },
    drawGridValue() {
      d3.selectAll(".axis--x .tick")
        .append("line")
        .attr("class", "bg-line")
        .attr("stroke-width", 2)
        .attr("stroke", "rgb(0,0,0)")
        .attr("stroke-opacity", 0.1)
        .attr("shape-rendering", "crispEdges")
        .attr("y2", this.yLength());
    },
    drawGridLabel() {
      d3.selectAll(".axis--y .tick")
        .append("line")
        .attr("class", "bg-line")
        .attr("stroke-width", 2)
        .attr("stroke", "rgb(0,0,0)")
        .attr("stroke-opacity", 0.1)
        .attr("shape-rendering", "crispEdges")
        .attr("x2", -this.xLength());
    },
    drawLabelPoint() {
      d3.selectAll(".axis--y .tick")
        .append("circle")
        .attr("r", this.pointSize)
        .attr("fill", this.pointColor);
    },

    xLength() {
      return this.allWidth - this.rightWidth - 10;
    },
    yLength() {
      return this.height - this.top - 10;
    },
  },
};
</script>
<style></style>
