<template>
  <div>
    <table>
      <tr>
        <td colspan="2">
          <el-button type="primary" @click="setcloumns" size="small"
            >列(显示|排序)</el-button
          >
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <el-radio-group v-model="ops.showTable" size="small">
            <el-radio-button :label="true">显示</el-radio-button>
            <el-radio-button :label="false">隐藏</el-radio-button>
          </el-radio-group>
        </td>
      </tr>
      <tr>
        <th>左边距：</th>
        <td>
          <el-input-number
            v-model="ops.left"
            controls-position="right"
            size="small"
          ></el-input-number>
        </td>
      </tr>
    </table>
    <set-column :show.sync="show_set_column" :columns.sync="columnsData" />
  </div>
</template>

<script>
export default {
  methods: {
    setcloumns() {
      this.show_set_column = true;
    },
  },
  watch: {
    ops: {
      immediate: true,
      deep: true,
      handler(v, ov) {
        this.$emit("change", v);
      },
    },
  },
  computed: {
    columnsData: {
      get() {
        return this.columns;
      },
      set(v) {
        this.$emit("update:columns", v);
      },
    },
  },
  data() {
    return {
      show_set_column: false,
      // columnsData: [],
      ops: {
        showTable: false,
        left: 0,
      },
    };
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [
          {
            prop: "key",
            label: "Key",
          },
        ];
      },
    },
  },
};
</script>

<style></style>
