<template>
  <el-dialog
    style="text-align: left"
    :visible.sync="visible"
    :width="'20%'"
    wrapperClosable
    ref="drawer"
    :close-on-click-modal="false"
  >
    <template slot="title">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >{{ title }}</el-checkbox
      >
    </template>
    <div
      style="
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      "
    >
      <draggable v-model="cols" v-bind="dragOptions">
        <template v-for="(t, i) in cols">
          <div v-if="!!t.prop" :key="t.prop" class="lx-draggable">
            <el-row>
              <el-col :span="22">
                <el-checkbox
                  :disabled="t.ismust"
                  v-model="select_set_column[t.prop]"
                  :label="Number(i + 1) + '、' + t.label"
                ></el-checkbox>
              </el-col>
              <el-col :span="2" style="text-align: right">
                <i class="el-icon-rank"></i>
              </el-col>
            </el-row>
          </div>
        </template>
      </draggable>
    </div>
    <slot></slot>
  </el-dialog>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "set-column",
  data() {
    return {
      select_set_column: {},
      checkAll: false,
      isIndeterminate: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      default: "调整显示列",
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  created() {
    const dd = {};
    for (const i in this.columns) {
      const t = this.columns[i];
      dd[t.prop] = !t.hide;
    }
    this.isIndeterminate = _.some(dd, (t) => t) && _.some(dd, (t) => !t);
    this.checkAll = _.some(dd, (t) => t);
    this.select_set_column = dd;
  },
  methods: {
    handleCheckAllChange(val) {
      const dd = {};
      for (const i in this.columns) {
        const t = this.columns[i];
        if (!t.ismust) {
          dd[t.prop] = val;
        } else {
          dd[t.prop] = !t.hide;
        }
      }
      this.select_set_column = dd;
      this.isIndeterminate = false;
    },
  },
  watch: {
    select_set_column: {
      handler: function (v, ov) {
        const res = _.map(this.cols, (t) => {
          if (t.ismust) {
            return { ...t };
          } else {
            return { ...t, hide: !v[t.prop] };
          }
        });
        this.isIndeterminate = _.some(v, (t) => t) && _.some(v, (t) => !t);
        this.$emit("update:columns", res);
      },
      deep: true,
    },
  },
  components: { draggable },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    visible: {
      get: function () {
        return this.show;
      },
      set: function (newValue) {
        this.$emit("update:show", newValue);
      },
    },
    cols: {
      get() {
        return this.columns;
      },
      set(v) {
        this.$emit("update:columns", v);
      },
    },
  },
};
</script>
<style lang="less" scoped>
.lx-draggable {
  border-top: 1px solid #ccc;
  padding: 8px;
  cursor: move;
}
.lx-draggable:hover {
  background-color: aquamarine;
}
</style>