<template>
  <div :style="{ height: height }" class="lx-gtree-contain">
    <div class="lx-gtree-tool">
      <el-row>
        <el-col :span="24">
          <h2>控制台</h2>
        </el-col>
        <el-col :span="24">
          <slot name="left"></slot>
        </el-col>
      </el-row>
    </div>
    <div class="lx-gtree-main">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    height: {
      type: String,
      default: "90vh",
    },
  },
};
</script>

<style>
.lx-gtree-contain {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.lx-gtree-main {
  position: absolute;
  left: 300px;
  right: 0;
  top: 0;
  bottom: 0;
}
.lx-gtree-tool {
  width: 300px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-right: 1px #ccc dashed;
  overflow-y: auto;
}
</style>
