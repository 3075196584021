<template>
  <page-box :height="height">
    <div :id="containerId" style="width: 100%; height: 100%"></div>
    <template #left>
      <el-collapse accordion v-model="activeName">
        <el-collapse-item name="1">
          <template slot="title"> <strong>节点定位</strong></template>
          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            style="padding-right: 10px; margin-bottom: -25px"
          >
            <el-form-item label="节点标签">
              <el-select
                v-model="form.label"
                placeholder="请选择节点标签"
                @change="change_label"
              >
                <template v-for="t in clum">
                  <el-option
                    :label="t.label"
                    :value="t.prop"
                    :key="t.rop"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="搜索节点">
              <el-input v-model="form.searchkey">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="search"
                ></el-button>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="clearselect">取消选择</el-button>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title"> <strong>文件</strong> </template>
          <el-button-group>
            <el-button type="primary" size="small" @click="savenwk"
              >另存为Newick</el-button
            >
            <el-button type="primary" size="small" @click="savesvg"
              >另存为SVG</el-button
            >
            <el-button type="primary" size="small" @click="savepdf"
              >另存为PDF</el-button
            >
          </el-button-group>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title"> <strong>布局与显示</strong> </template>
          <el-button-group>
            <el-button type="primary" size="small" @click="restore"
              >还原</el-button
            >
            <el-button type="primary" size="small" @click="redraw"
              >重绘</el-button
            >
            <el-button type="primary" size="small" @click="go_center"
              >回到中心</el-button
            >
          </el-button-group>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title"> <strong>节点样式</strong> </template>
          <el-form
            ref="form"
            :model="form"
            label-width="80px"
            style="padding-right: 10px; margin-bottom: -25px"
          >
            <!-- <el-form-item label="显示饼图">
                  <el-switch v-model="form.showpie" @change="show_pie"></el-switch>
                </el-form-item> -->
            <el-form-item label="显示标签">
              <el-switch
                v-model="form.showlabel"
                @change="show_label"
              ></el-switch>
            </el-form-item>
            <el-form-item label="字体大小">
              <el-input-number
                v-model="form.label_size"
                :min="1"
                :max="1000"
                label="字体大小"
                @input="set_label_size"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="节点大小">
              <el-input-number
                v-model="form.point_size"
                :min="1"
                :max="500"
                :step="1"
                label="节点大小"
                @input="set_point_size"
              ></el-input-number>
            </el-form-item>
            <!-- <el-form-item label="峰度">
                  <el-input-number
                    v-model="form.kurtosis_size"
                    :min="1"
                    :max="150"
                    label="峰度"
                    @input="set_kurtosis_size"
                  ></el-input-number>
                </el-form-item> -->
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="5">
          <template slot="title"> <strong>分支样式</strong> </template>
          <el-form
            ref="form"
            :model="form"
            label-width="90px"
            style="padding-right: 10px; margin-bottom: -25px"
          >
            <el-form-item label="对数比例尺">
              <el-switch v-model="form.log" @change="show_as_log"></el-switch>
            </el-form-item>
            <el-form-item label="显示标签">
              <el-switch
                v-model="form.showlinklabel"
                @change="show_link_label"
              ></el-switch>
            </el-form-item>
            <el-form-item label="字体大小">
              <el-input-number
                v-model="form.link_label_size"
                :min="1"
                :max="1000"
                label="字体大小"
                @input="set_link_label_size"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="缩放比例">
              <el-input-number
                v-model="form.Scaling"
                :min="1"
                :max="500"
                label="节点大小"
                @input="set_Scaling"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="合并分支">
              <el-input-number
                v-model="form.Collapse_Branches"
                :min="0"
                :max="150"
                label="合并分支"
                @input="set_Collapse_Branches"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="分支长度(>)">
              <el-input-number
                v-model="form.link_length"
                :min="1"
                :max="9999"
                label="分支长度"
                @input="set_link_length"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="">
              <el-radio-group
                v-model="form.link_type"
                size="mini"
                @change="change_link_length_status"
              >
                <el-radio-button label="display">显示</el-radio-button>
                <el-radio-button label="hide">隐藏</el-radio-button>
                <el-radio-button label="cap">缩短</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-collapse-item>
        <el-collapse-item name="6">
          <template slot="title"> <strong>图例设置</strong> </template>
          <el-form
            ref="form"
            :model="form"
            label-width="90px"
            style="padding-right: 10px; margin-bottom: -25px"
          >
            <el-form-item label="配色方案">
              <el-select
                v-model="form.colors"
                placeholder="请选择配色方案"
                @change="change_colors"
              >
                <el-option value="category" label="Category"></el-option>
                <el-option value="category2" label="Category 2"></el-option>
                <el-option value="gradient" label="Gradient: Warm"></el-option>
                <el-option
                  value="gradient_cool"
                  label="Gradient: Cool"
                ></el-option>
                <el-option value="gradient_rainbow" label="Rainbow"></el-option>
                <el-option
                  value="gradient_rainbow2"
                  label="Rainbow Bright"
                ></el-option>
                <el-option
                  value="gradient_rainbow3"
                  label="Rainbow Dark"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分组数量">
              <el-switch
                v-model="form.show_group_count"
                @change="show_group_count"
              ></el-switch>
            </el-form-item>
            <el-form-item label="分组字段">
              <el-select
                v-model="form.group"
                placeholder="请选择分组字段"
                @change="group_change"
              >
                <template v-for="t in clum">
                  <el-option
                    :label="t.label"
                    :value="t.prop"
                    :key="t.rop"
                  ></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="分组上限">
              <el-input-number
                v-model="form.group_count"
                :min="1"
                :max="328"
                label="分组上限"
                @input="set_group_count"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="最小分组数">
              <el-input-number
                v-model="form.group_min"
                :min="0"
                :max="9999"
                label="最小分组数"
                @input="set_group_min"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </template>
    <div class="lx-select-color" v-show="$refs.color && $refs.color.showPicker">
      <el-color-picker
        ref="color"
        v-model="color"
        :predefine="predefineColors"
        @change="color_change"
      >
      </el-color-picker>
    </div>
  </page-box>
</template>
<script>
import * as d3 from "d3";
import D3MSTree from "../static/js/d3_m_tree";
import { saveTextAsFile, saveAsPdf } from "../static/js/help";
import _ from "lodash";
import pageBox from "./page-container/index.vue";

let the_tree = null;
export default {
  name: "gtree",
  data() {
    return {
      showcolor: false,
      color: "#ff4500",
      color_key: "",
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "rgb(255, 120, 0)",
        "hsv(51, 100, 98)",
        "hsva(120, 40, 94)",
        "hsl(181, 100%, 37%)",
        "hsl(209, 100%, 56%)",
      ],
      lableAxis: [],
      showData: [],
      form: {
        label: "key",
        searchkey: "",
        showpie: true,
        showlabel: false,
        label_size: 8,
        point_size: 10,
        kurtosis_size: 100,
        log: true,
        showlinklabel: true,
        link_label_size: 8,
        Scaling: 100,
        Collapse_Branches: 0,
        link_length: 1,
        link_type: "display",
        colors: "category",
        group: "key",
        group_count: 328,
        group_min: 0,
      },
      activeName: "1",
    };
  },
  props: {
    height: {
      type: String,
      default: "90vh",
    },
    width: {
      type: String,
      default: "600",
    },
    valueTitle: {
      type: String,
      default: "位点差异个数",
    },
    containerId: {
      type: String,
      default: "xin-grape-chart",
    },
    nwk: {
      type: String,
      required: true,
      default: "",
    },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    clum: {
      type: Array,
      default() {
        return [
          {
            prop: "key",
            label: "key",
          },
        ];
      },
    },
    selectTable: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {},
  mounted() {
    if (!this.nwk || this.nwk.indexOf("(") < 0) {
      console.log("数据格式不合法");
      return;
    }
    this.init(false);
  },
  computed: {
    categoryLabel() {
      const a = this.form.group;
      if (!!a) {
        return _.find(this.clum, (t) => t.prop == a).label;
      }
      return "Key";
    },
  },
  methods: {
    clearselect() {
      if (the_tree) the_tree.clearSelection();
    },
    savenwk() {
      saveTextAsFile(the_tree.getTreeAsNewick(), "gtree.nwk");
    },
    savesvg() {
      saveTextAsFile(the_tree.getSVG(), "gtree.svg");
    },
    savepdf() {
      const svg = document.querySelector("#mst-svg");
      const w = svg.clientWidth;
      const h = svg.clientHeight;
      if (this.$listeners["savepdf"]) {
        this.$emit("savepdf", { svg: the_tree.getSVG(), w, h });
      } else {
        saveAsPdf(the_tree.getSVG(), "gtree", w, h);
      }
    },
    set_group_min(v) {
      this.changeCategory();
    },
    set_group_count(v) {
      this.changeCategory();
    },
    changeCategory() {
      if (!the_tree) {
        return;
      }
      var category = this.form.group;
      if (category != "nothing") {
        the_tree.metadata_info[category] = {
          label: this.categoryLabel,
          show_group_count: !!this.form.show_group_count,
          category_num: Number(this.form.group_count),
          coltype: "character",
          grouptype: "size",
          colorscheme: this.form.colors,
          minnum: Number(this.form.group_min),
        };
        the_tree.changeCategory(category);
      }
    },
    show_group_count(v) {
      this.changeCategory();
    },
    change_colors(v) {
      this.changeCategory();
    },
    set_kurtosis_size(v) {
      if (the_tree) the_tree.setRelativeNodeSize(Number(v));
    },
    set_point_size(v) {
      if (the_tree) the_tree.setNodeSize(Number(v));
    },
    set_label_size(v) {
      if (the_tree) the_tree.setNodeFontSize(Number(v));
    },
    set_link_label_size(v) {
      if (the_tree) the_tree.setLinkFontSize(Number(v));
    },
    set_Scaling(v) {
      if (the_tree) the_tree.setLinkLength(Number(v));
    },
    set_Collapse_Branches(v) {
      if (the_tree) the_tree.collapseNodes(Number(v));
    },
    set_link_length(vv) {
      if (!the_tree) {
        return;
      }
      var max = vv;
      const v = this.form.link_type;
      if (!max) {
        max = the_tree.max_link_distance + 1;
      }
      if (v == "hide") {
        the_tree.setMaxLinkLength(the_tree.max_link_distance + 1);
        the_tree.setHideLinkLength(max);
      } else if (v == "cap") {
        the_tree.setHideLinkLength(the_tree.max_link_distance + 1);
        the_tree.setMaxLinkLength(max);
      } else {
        the_tree.setMaxLinkLength(the_tree.max_link_distance + 1);
        the_tree.setHideLinkLength(the_tree.max_link_distance + 1);
      }
    },
    change_link_length_status(v) {
      if (!the_tree) {
        return;
      }
      var max = this.form.link_length;
      if (!max) {
        max = the_tree.max_link_distance + 1;
      }
      if (v == "hide") {
        the_tree.setMaxLinkLength(the_tree.max_link_distance + 1);
        the_tree.setHideLinkLength(max);
      } else if (v == "cap") {
        the_tree.setHideLinkLength(the_tree.max_link_distance + 1);
        the_tree.setMaxLinkLength(max);
      } else {
        the_tree.setMaxLinkLength(the_tree.max_link_distance + 1);
        the_tree.setHideLinkLength(the_tree.max_link_distance + 1);
      }
    },
    search() {
      const keyword = this.form.searchkey;
      if (keyword) {
        var ids = the_tree.searchMetadata(keyword, this.form.label);
        the_tree.highlightNodes(ids);
      }
    },
    change_label(v) {
      if (the_tree) the_tree.setNodeText(v);
    },
    group_change(v) {
      this.changeCategory();
    },
    show_link_label(v) {
      if (the_tree) the_tree.showLinkLabels(v);
    },
    show_as_log(v) {
      if (the_tree) {
        the_tree.setLinkLength(10);
        the_tree.setLogLinkScale(v);
        the_tree.setLinkLength(100);
        the_tree.centerGraph();
      }
    },
    show_label(v) {
      if (the_tree) the_tree.showNodeLabels(v);
    },
    show_pie(v) {
      if (the_tree) the_tree.showIndividualSegments(v);
    },
    restore() {
      this.init(false);
    },
    redraw() {
      this.init(true);
    },
    go_center() {
      if (the_tree) the_tree.centerGraph();
    },
    clear() {
      the_tree = null;
      let main = d3.select("#" + this.containerId);
      main.html("");
    },
    init(isforce) {
      this.clear();
      the_tree = new D3MSTree(
        this.containerId,
        {
          nwk: this.nwk,
          layout_algorithm: isforce ? "force" : "greedy",
          initial_category: "key",
          metadata: this.selectTable,
        },
        function (tree, msg) {
          if (msg === "complete") {
            tree.centerGraph();
          }
        }
      );
      this.tooltip();
      const _this = this;
      the_tree.addSegmentOverListener(function (d) {
        if (the_tree.display_category === "nothing") {
          var node_ids = the_tree.grouped_nodes[d.data.idx];
          var value = $.map(node_ids, function (idx) {
            return the_tree.metadata[idx].Name
              ? the_tree.metadata[idx].Name
              : the_tree.metadata[idx].key;
          }).join("<br>");
          _this.showToolTip(value);
        } else {
          var display = d.data.type;
          _this.showToolTip(display + "(" + d.data.value + ")");
        }
      });
      the_tree.addSegmentOutListener(function (d) {
        _this.hideToolTip();
      });
      the_tree.addLinkOverListener(function (d) {
        _this.showToolTip(_this.valueTitle + ":" + d.value);
      });
      the_tree.addLinkOutListener(function (d) {
        _this.hideToolTip();
      });
      the_tree.legendItemClicked = function (data) {
        _this.showcolor = true;
        _this.color_key = data.value;
        _this.color = data.colour;
        _this.$refs.color.showPicker = true;
      };
    },
    color_change(v) {
      this.showcolor = false;
      the_tree.setColour(this.form.group, this.color_key, v);
      this.changeCategory();
    },
    tooltip() {
      let main = d3.select("#" + this.containerId);
      main
        .append("div")
        .attr("class", "tooltip lx-tooltip")
        .style("position", "absolute")
        .style("background", "lightsteelblue")
        .style("padding", "5px")
        .style("border-radius", "8px")
        .style("text-align", "center")
        .style("font", "16px sans-serif")
        .style("opacity", 0)
        .style("z-index", 9);
    },
    showToolTip(msg, e) {
      if (!e) {
        e = d3.event;
      }
      let tooltip_div = d3.select(".lx-tooltip");
      tooltip_div.transition().duration(200).style("opacity", 0.9);
      tooltip_div
        .html(msg)
        .style("left", Number(e.pageX - 300 - this.left) + "px")
        .style("top", Number(e.pageY - 28 - this.top) + "px")
        .style("height", "auto");
    },
    hideToolTip() {
      let tooltip_div = d3.select(".lx-tooltip");
      tooltip_div.transition().duration(500).style("opacity", 0);
    },
  },
  components: { pageBox },
};
</script>
<style scoped>
/* .lx-gtree-contain {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.lx-gtree-main {
  height: 100%;
  left: 300px;
  right: 0px;
  top: 0;
  position: absolute;
}
.lx-gtree-tool {
  width: 300px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-right: 1px #ccc dashed;
  overflow-y: auto;
} */
.lx-select-color {
  position: fixed;
  top: 200px;
  right: 500px;
}
</style>
