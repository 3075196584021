import Canvg from 'canvg';
import jsPDF from 'jspdf';

const svg2canvas = (svg, w, h) => {
    const canvas = document.createElement('canvas');
    const width = w;
    const height = h;
    const ctx = canvas.getContext('2d');
    const v = Canvg.fromString(ctx, svg,
        {
            ignoreMouse: true,   // 不理会鼠标事件
            ignoreAnimation: true,  // 不理会动画
        });

    // 保持canvas大小和原来的svg一致
    v.resize(width, height);

    // 将svg替换成canvas
    v.render();
    return canvas;
}

export function saveAsPdf(svg, name, w, h) {
    const canvas = svg2canvas(svg, w, h);
    const pageData = canvas.toDataURL('image/png');
    let pdf = new jsPDF('', 'pt', 'a4');
    //一页pdf显示html页面生成的canvas高度;
    var pageHeight = w / 592.28 * 841.89;
    //未生成pdf的html页面高度
    var leftHeight = h;
    //页面偏移
    var position = 0;
    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    var imgWidth = 595.28;
    var imgHeight = 592.28 / w * h;
    //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    //当内容未超过pdf一页显示的范围，无需分页
    if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
    } else {
        while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight;
            position -= 841.89;
            //避免添加空白页
            if (leftHeight > 0) {
                pdf.addPage();
            }
        }
    }

    pdf.save(name + ".pdf");
}


export function saveTextAsFile(text, suggested_name) {
    var name = suggested_name;
    if (!name) {
        return;
    }
    //windows - don't actually use the file download anchor
    if (window.navigator.msSaveOrOpenBlob) {
        var data = new Blob([text], { type: 'text/plain' });
        window.navigator.msSaveBlob(data, name);
    }
    //others
    else {
        var data = new Blob([text], { type: 'text/plain' });
        var textFile = window.URL.createObjectURL(data);
        //set file name and contents before click event propogates
        const link = document.createElement('a')
        link.href = textFile
        link.download = name
        document.body.appendChild(link)
        link.click()
        window.setTimeout(function () {
            document.body.removeChild(link)
        }, 0)
    }
};