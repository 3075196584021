<template>
  <el-button-group>
    <el-button type="primary" size="small" @click="add">放大</el-button>
    <el-button type="primary" size="small" @click="re">恢复</el-button>
    <el-button type="primary" size="small" @click="sub">缩小</el-button>
  </el-button-group>
</template>

<script>
export default {
  methods: {
    add() {
      window.zoomTiger.zoomIn();
    },
    sub() {
      window.zoomTiger.zoomOut();
    },
    re() {
      window.zoomTiger.reset();
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
