<template>
  <div>
    <div style="margin-bottom: 10px">
      <el-dropdown trigger="click">
        <el-button type="primary" size="small">
          添加数据分组<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <template v-for="(t, i) in columns">
            <el-dropdown-item :key="i" @click.native="addGroup(t)">{{
              t.label
            }}</el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
      &nbsp;
      <el-dropdown trigger="click" v-if="group.length > 0">
        <el-button type="primary" size="small">
          添加固定分组<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <template v-for="(t, i) in group">
            <el-dropdown-item :key="i" @click.native="addGroup_more(t)">{{
              t.label
            }}</el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-tabs
      v-model="activeGroup"
      type="card"
      closable
      @tab-remove="removeGroup"
    >
      <el-tab-pane
        v-for="(item, index) in groupTabs"
        :key="index"
        :label="item.label"
        :name="item.prop"
      >
        <div>
          <el-radio-group v-model="item.show" size="small">
            <el-radio-button :label="true">显示</el-radio-button>
            <el-radio-button :label="false">隐藏</el-radio-button>
          </el-radio-group>
        </div>
        <table>
          <tr v-if="item.type == 'one'">
            <th>标题：</th>
            <td>
              <el-input
                v-model="item.label"
                size="small"
                placeholder="请输入内容"
              ></el-input>
            </td>
          </tr>

          <tr>
            <th>标题：</th>
            <td>
              <el-radio-group v-model="item.isVertical" size="small">
                <el-radio-button :label="true">垂直</el-radio-button>
                <el-radio-button :label="false">水平</el-radio-button>
              </el-radio-group>
            </td>
          </tr>
          <tr v-if="item.isVertical">
            <th>标题水平：</th>
            <td>
              <el-input-number
                v-model="item.label_x"
                controls-position="right"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr v-if="item.isVertical">
            <th>标题垂直：</th>
            <td>
              <el-input-number
                v-model="item.label_y"
                controls-position="right"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr>
            <th>形状：</th>
            <td>
              <el-select v-model="item.style" placeholder="请选择" size="small">
                <el-option label="矩形" value="rect"> </el-option>
                <el-option label="圆" value="circle"> </el-option>
                <el-option label="点" value="point" v-if="item.type == 'one'">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr v-if="item.style !== 'point'">
            <th>左边距：</th>
            <td>
              <el-input-number
                v-model="item.left"
                controls-position="right"
                :min="3"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr v-if="item.style == 'point'">
            <th>大小：</th>
            <td>
              <el-input-number
                v-model="item.point_r"
                controls-position="right"
                :min="3"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr v-if="item.style == 'circle'">
            <th>半径：</th>
            <td>
              <el-input-number
                v-model="item.r"
                controls-position="right"
                :min="5"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr v-if="item.style == 'rect'">
            <th>长度：</th>
            <td>
              <el-input-number
                v-model="item.w"
                controls-position="right"
                :min="5"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr v-if="item.style == 'rect'">
            <th>宽度：</th>
            <td>
              <el-input-number
                v-model="item.h"
                controls-position="right"
                :min="5"
                size="small"
              ></el-input-number>
            </td>
          </tr>
          <tr v-if="item.type == 'more'">
            <th>水平间距：</th>
            <td>
              <el-input-number
                v-model="item.space"
                controls-position="right"
                :min="0"
                size="small"
              ></el-input-number>
            </td>
          </tr>
        </table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  watch: {
    groupTabs: {
      handler(v, ov) {
        this.$emit("change", v);
      },
      deep: true,
    },
  },
  methods: {
    creatDefaultGroup() {
      const _this = this;
      this.defaultGroup.forEach((t) => {
        const c = _.find(_this.columns, (c) => c.prop == t);
        if (c) {
          _this.addGroup(c);
        }
        const g = _.find(_this.group, (c) => c.prop == t);
        if (g) {
          _this.addGroup_more(g);
        }
      });
    },
    computedLeftCreat() {
      const dd = _.filter(this.groupTabs, (t) => t.style != "point" && t.show);
      const maxLeft = _.maxBy(dd, (t) => t.left);
      const now = this.left;
      if (!maxLeft) {
        return now;
      }
      const { type, style, left, w, r, space, column } = maxLeft;
      if (type == "one" && style == "rect") {
        return left + w + space;
      }
      if (type == "one" && style == "circle") {
        return left + r * 2 + space;
      }
      if (type == "more" && style == "rect") {
        return left + (w + space) * column.length + 5;
      }
      if (type == "more" && style == "circle") {
        return left + (2 * r + space) * column.length + 5;
      }
    },
    creatColors(data) {
      let color = [];
      for (let i in data) {
        color.push("#" + Math.floor(Math.random() * 0xffffff).toString(16));
      }
      return color;
    },
    addGroup(t) {
      let newTabName = t.prop;
      if (!_.some(this.groupTabs, (g) => g.prop == newTabName)) {
        const group = _.groupBy(this.tableData, (s) => s[newTabName]);
        const colors = this.creatColors(Object.keys(group));

        const left = this.computedLeftCreat();
        this.groupTabs.push({
          label: t.label,
          prop: newTabName,
          type: "one",
          isVertical: false,
          label_x: 0,
          label_y: 0,
          w: 80,
          h: 28,
          r: 13,
          space: 5,
          point_r: 5,
          left,
          show: true,
          style: "rect", //rect,point,circle
          colors: t.colors ? t.colors : colors,
          group: t.group ? t.group : Object.keys(group),
        });
        this.activeGroup = newTabName;
      }
    },
    addGroup_more(t) {
      let newTabName = t.prop;
      if (!_.some(this.groupTabs, (g) => g.prop == newTabName)) {
        const groupData = _.find(this.group, (r) => r.prop == newTabName);
        const data = groupData.data;
        const columns = Object.keys(data[0]);
        let group = [],
          column = [];
        columns.forEach((c) => {
          if (c != "key") {
            const _group = _.groupBy(data, (d) => d[c]);
            group = _.concat(group, Object.keys(_group));
            column.push(c);
          }
        });
        group = t.group ? t.group : _.uniq(group);
        const colors = t.colors
          ? t.colors
          : this.creatColors(Object.keys(group));

        const left = this.computedLeftCreat();
        this.groupTabs.push({
          label: t.label,
          prop: newTabName,
          type: "more",
          isVertical: false,
          label_x: 0,
          label_y: 0,
          w: 80,
          h: 28,
          r: 13,
          space: 1,
          left,
          show: true,
          data,
          style: "rect", //rect,circle
          colors,
          group,
          column,
        });
        this.activeGroup = newTabName;
      }
    },
    removeGroup(targetName) {
      let tabs = this.groupTabs;
      let activeName = this.activeGroup;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.prop === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.prop;
            }
          }
        });
      }

      this.activeGroup = activeName;
      this.groupTabs = tabs.filter((tab) => tab.prop !== targetName);
    },
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [
          {
            prop: "key",
            label: "Key",
          },
        ];
      },
    },
    left: {
      type: Number,
      default: 0,
    },
    group: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultGroup: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.creatDefaultGroup();
  },
  data() {
    return {
      //激活的分组标签
      activeGroup: "",
      //分组列表
      groupTabs: [],
      //分组数量
      groupIndex: 0,
    };
  },
};
</script>

<style></style>
