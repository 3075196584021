<template>
  <page-box :height="boxHeight">
    <template #left>
      <el-collapse accordion v-model="activeName">
        <el-collapse-item name="1">
          <template slot="title"> <strong>文件</strong> </template>
          <file-control
            @savenwk="savenwk"
            @savepdf="savepdf"
            @savesvg="savesvg"
          />
        </el-collapse-item>
        <el-collapse-item name="5">
          <template slot="title"> <strong>视图</strong> </template>
          <view-control />
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title"> <strong>基本设置</strong></template>
          <base-control @change="baseChange" :type="type" />
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title"> <strong>表格设置</strong></template>
          <table-control :columns.sync="all_columns" @change="tableChange" />
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title"> <strong>分组设置</strong></template>
          <group-control
            :columns="all_columns"
            :group="group"
            :defaultGroup="defaultGroup"
            :tableData="tableData"
            @change="groupChange"
          />
        </el-collapse-item>
      </el-collapse>
    </template>

    <circle-chart
      ref="chart"
      :columns="all_columns"
      :groupTabs="groupTabs"
      :tableData="tableData"
      :nwk="nwk"
      :containerName="containerName"
      :options="baseOptions"
      :tableOptions="tableOptions"
      :type="type"
      v-if="baseOptions.isCircle"
    />
    <rectangular-chart
      v-else
      ref="chart"
      :columns="all_columns"
      :groupTabs="groupTabs"
      :tableData="tableData"
      :nwk="nwk"
      :containerName="containerName"
      :options="baseOptions"
      :tableOptions="tableOptions"
      :type="type"
    />
    <chart-legend
      ref="legend"
      :containerName="containerName"
      :groupTabs="groupTabs"
    />
  </page-box>
</template>

<script>
import * as d3 from "d3";
import _ from "lodash";
import { saveTextAsFile, saveAsPdf } from "@/assets/js/help";
import pageBox from "../page-container/index.vue";
import baseControl from "./base-control";
import fileControl from "./file-control";
import tableControl from "./table-control";
import groupControl from "./group-control";
import viewControl from "./view-control";
import circleChart from "../circle-chart";
import rectangularChart from "../rectangular-chart";
import chartLegend from "../chart-legend";

export default {
  name: "d-tree-show",
  data() {
    return {
      baseOptions: {},
      tableOptions: {},
      groupTabs: [],
      activeName: "1",
      all_columns: [],
    };
  },
  computed: {},
  watch: {
    columns: {
      handler(v, ov) {
        this.all_columns = v;
      },
      immediate: true,
      deep: true,
    },
    all_columns: {
      handler(v, ov) {
        this.reInit();
      },
      deep: true,
    },
  },
  mounted() {
    this.reInit();
  },
  methods: {
    reInit() {
      if (!this.nwk) {
        console.warn("未传递nwk，无法绘制！");
        return;
      }
      this.$nextTick(function () {
        if (this.$refs.chart) this.$refs.chart.init();
        if (this.$refs.legend) this.$refs.legend.init();
      });
    },
    baseChange(v) {
      this.baseOptions = v;
      this.reInit();
    },
    tableChange(v) {
      this.tableOptions = v;
      this.reInit();
    },
    groupChange(v) {
      this.groupTabs = v;
      this.reInit(true);
    },
    savenwk() {
      saveTextAsFile(this.nwk, "tree.nwk");
    },

    savesvg() {
      const _this = this;
      window.setTimeout(() => {
        const data = _this.getSVG();
        saveTextAsFile(data.svg, "tree.svg");
      }, 200);
    },
    getSVG() {
      // const size = window.zoomTiger.getSizes();
      // const room = size.realZoom;
      let svg = document.getElementById("lx-svg__" + this.containerName);
      let leg = document.getElementById("lx-svg-legend__" + this.containerName);
      let legBox = document.getElementById("lx-svg-legend-box");
      const leghtml = leg.outerHTML;
      var ori_pos = document.getElementById(this.containerName + "1");
      leg.setAttribute(
        "transform",
        "translate(" + ori_pos.offsetLeft + "," + ori_pos.offsetTop + ")"
      );
      svg.appendChild(leg);
      const _height = leg.getAttribute("height");
      const height = svg.scrollHeight;
      svg.setAttribute("height", _height < height ? height : _height);
      const svgData = svg.outerHTML;
      const w = svg.clientWidth;
      let h = svg.clientHeight;
      const l_h = legBox.scrollHeight;
      h = h < l_h ? l_h : h;
      const s_h = svg.scrollHeight;
      h = h < s_h ? s_h : h;
      leg.setAttribute("transform", "translate(0,0)");
      document.getElementById("lx-svg-legend-box").appendChild(leg);
      // const svgDataOK = ["<svg ", svgData.substring(5, 9999999)].join("");
      return {
        svg: svgData,
        w,
        h,
      };
    },
    savepdf() {
      const _this = this;
      window.setTimeout(() => {
        const data = _this.getSVG();
        if (this.$listeners["savepdf"]) {
          this.$emit("savepdf", data);
        } else {
          saveAsPdf(data.svg, "tree", data.w, data.h);
        }
      }, 200);
    },
  },
  props: {
    boxHeight: {
      type: String,
      default: "90vh",
    },
    containerName: {
      type: String,
      default: "xin-jl-chart",
    },
    type: {
      type: String,
      default: "jl",
    },
    defaultShowTable: {
      type: Boolean,
      default: false,
    },
    defaultColumnWidth: {
      type: Number,
      default: 150,
    },
    nwk: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default() {
        return [
          {
            prop: "key",
            label: "Key",
          },
        ];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    group: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultGroup: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    pageBox,
    baseControl,
    fileControl,
    tableControl,
    groupControl,
    circleChart,
    chartLegend,
    rectangularChart,
    viewControl,
  },
};
</script>

<style lang="less">
.link--active {
  stroke: #000 !important;
  stroke-width: 1.5px;
}

.link-extension--active {
  stroke-opacity: 0.6;
}

.label--active {
  font-weight: bold;
}
.lx-legend {
  position: absolute;
  right: 0px;
  top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
}
.lx-select-color {
  position: fixed;
  top: 200px;
  right: 500px;
}
</style>
